import React, { memo } from 'react'
import Script from 'next/script'
import PropTypes from 'prop-types'

import checkProps from '@jam3/react-check-extra-props'
import NextHead from 'next/head'
import { useRouter } from 'next/router'

export const FAVICON_ID = 'faviconId'

function Head({ title, description, keywords, shareImageUrl, handle, robots }) {
  const router = useRouter()
  const url = `${process.env.NEXT_PUBLIC_WEBSITE_SITE_URL}${router.asPath}`

  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script>
        {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                    page_path: window.location.pathname,
                    });
                `}
      </Script>
      <NextHead>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no"
        />
        <title>{title}</title>
        <meta
          name="description"
          content={description}
        />
        <meta
          name="keywords"
          content={keywords}
        />
        {/* Generate favicons in https://realfavicongenerator.net */}
        <meta
          name="theme-color"
          content="#ffffff"
        />
        <meta
          name="msapplication-TileColor"
          content="#ffffff"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/favicons/site.webmanifest"
          crossOrigin="use-credentials"
        />
        <link
          rel="mask-icon"
          href="/favicons/safari-pinned-tab.svg"
          color="#000000"
        />
        <link
          rel="shortcut icon"
          href="/favicons/favicon.ico"
        />
        <meta
          name="msapplication-config"
          content="/favicons/browserconfig.xml"
        />

        {!robots && (
          <meta
            name="robots"
            content="noindex"
          />
        )}

        {/* Share meta tags: OG */}
        <meta
          property="og:title"
          content={title}
        />
        <meta
          property="og:description"
          content={description}
        />
        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:url"
          content={url}
        />
        <meta
          property="og:site_name"
          content={title}
        />
        <meta
          property="og:image:width"
          content="1200"
        />
        <meta
          property="og:image:height"
          content="630"
        />
        <meta
          property="og:image"
          content={shareImageUrl}
        />

        {/* Share meta tags: OG */}
        <meta
          name="twitter:card"
          content="summary"
        />
        <meta
          name="twitter:image"
          content={shareImageUrl}
        />
        {title && (
          <meta
            name="twitter:title"
            content={title}
          />
        )}
        {description && (
          <meta
            name="twitter:description"
            content={description}
          />
        )}
        {handle && (
          <meta
            name="twitter:site"
            content={handle}
          />
        )}
        {handle && (
          <meta
            name="twitter:creator"
            content={handle}
          />
        )}

        <link
          rel="canonical"
          href={url}
        />

        {/* <GtmScript /> */}
      </NextHead>
    </>
  )
}

Head.propTypes = checkProps({
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  shareImageUrl: PropTypes.string,
  robots: PropTypes.bool,
})

Head.defaultProps = {
  title: '',
  description: '',
  keywords: '',
  shareImageUrl: '',
  robots: true,
}

export default memo(Head)
