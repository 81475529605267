import { fields as portfolioItemFields } from './portfolioItem'

export const fields = `
	_type,
  portfolioItems[]-> {
    ${portfolioItemFields}
  }
`

export const fragment = (name = 'portfolioItems') => `${name}{ ${fields} }`

export default fragment
