const isBrowser = typeof window !== 'undefined'

const detect = {
  device: { isDesktop: true },
  browser: {},
  os: {},
  bots: {},
}

if (isBrowser) {
  detect.device = require('@jam3/detect').device
  detect.browser = require('@jam3/detect').browser
  detect.os = require('@jam3/detect').os
  detect.bots = require('@jam3/detect').bots
}

export const getIsTouchDevice = () => {
  return isBrowser && ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
}

export const isMobile = typeof window !== 'undefined' && detect.device.isMobile && !detect.device.isTablet

const testImages = {
  lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
  lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
  alpha:
    'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
  animation:
    'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
}

export function checkWebpSupport(feature, callback) {
  var img = new Image()
  img.onload = function () {
    var result = img.width > 0 && img.height > 0
    callback(result)
  }
  img.onerror = function () {
    callback(false)
  }
  img.src = 'data:image/webp;base64,' + testImages[feature]
}

export default detect
