import React, { memo, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import styles from './PortfolioSingleContent.module.scss'
import useStore from 'store'
import CaretUp from 'assets/svgs/caret-up.svg'
import ScrollIndicator from '../ScrollIndicator/ScrollIndicator'
import { PORTFOLIO_TRANSITION_DURATION, PORTFOLIO_SINGLE_TRANSITION_OUT_DURATION } from 'data'
import gsap from 'gsap'
import SplitText, { letterClass } from 'utils/splitText'
import useBreakpoint from 'utils/hooks/use-breakpoint'
import { white, black } from 'styles/export-vars.module.scss'

function PortfolioSingleContent({ className, portfolioItems }) {
  const topRef = useRef()
  const bottomRef = useRef()
  const [data, setData] = useState(null)
  const activePortfolioItemIndex = useStore(state => state.activePortfolioItemIndex)
  const scrollIndicatorRef = useRef()
  const projectTitleRef = useRef()
  const brandRef = useRef()
  const portfolioState = useStore(state => state.portfolioState)
  const setPortfolioState = useStore(state => state.setPortfolioState)
  const bottomRightRef = useRef()
  const mobileContentRef = useRef()
  const [teammateCommentsActive, setTeammateCommentsActive] = useState(false)
  const commentsRef = useRef()
  const { isMobile } = useBreakpoint()
  const [hoveringBack, setHoveringBack] = useState(false)

  useEffect(() => {
    if (activePortfolioItemIndex === null) {
      return setData(null)
    }

    const activeData = portfolioItems[activePortfolioItemIndex]
    setData(activeData)
  }, [activePortfolioItemIndex, portfolioItems])

  useEffect(() => {
    if (!data) return
    SplitText.init(projectTitleRef.current, {
      spacing: 0.3,
    })
  }, [data])

  useEffect(() => {
    if (!commentsRef.current) return

    gsap.to(commentsRef.current, {
      autoAlpha: teammateCommentsActive ? 1 : 0,
      duration: 0.15,
    })
  }, [teammateCommentsActive])

  useEffect(() => {
    if (!data) return
    if (portfolioState === 'SINGLE') return

    const isActive = portfolioState === 'TRANSITIONING_TO_SINGLE'

    setTeammateCommentsActive(false)

    // MOBILE ANIMATION
    if (mobileContentRef.current) {
      gsap.killTweensOf(mobileContentRef.current)

      gsap.to(mobileContentRef.current, {
        autoAlpha: isActive ? 1 : 0,
        duration: isActive ? PORTFOLIO_TRANSITION_DURATION : PORTFOLIO_SINGLE_TRANSITION_OUT_DURATION,
        delay: isActive ? PORTFOLIO_TRANSITION_DURATION * 0.5 : 0,
      })
    }

    // DESKTOP ANIMATION
    if (topRef.current) {
      gsap.fromTo(
        [topRef.current, bottomRightRef.current],
        {
          y: isActive ? 50 : 0,
          autoAlpha: isActive ? 0 : 1,
        },
        {
          y: isActive ? 0 : 50,
          autoAlpha: isActive ? 1 : 0,
          ease: 'Power3.easeOut',
          duration: isActive ? PORTFOLIO_TRANSITION_DURATION : PORTFOLIO_SINGLE_TRANSITION_OUT_DURATION,
        },
      )

      gsap.to(brandRef.current, {
        autoAlpha: isActive ? 0.5 : 0,
        ease: 'Power3.easeOut',
        duration: isActive ? PORTFOLIO_TRANSITION_DURATION : PORTFOLIO_SINGLE_TRANSITION_OUT_DURATION,
      })

      gsap.killTweensOf(scrollIndicatorRef.current)

      gsap.to(scrollIndicatorRef.current, {
        autoAlpha: isActive ? 1 : 0,
        duration: isActive ? PORTFOLIO_TRANSITION_DURATION : PORTFOLIO_SINGLE_TRANSITION_OUT_DURATION,
      })

      gsap.set(projectTitleRef.current, {
        autoAlpha: 1,
      })

      const letters = projectTitleRef.current.querySelectorAll(`.${letterClass}`)
      const lettersArray = [...letters]
      const middleChar = Math.round(letters.length / 2)
      gsap.fromTo(
        lettersArray,
        {
          skewX: i => {
            if (!isActive) return 0

            const realIndex = i + 1

            let multiplier = (1 - realIndex / middleChar) * -1
            if (realIndex > middleChar) {
              multiplier = realIndex / middleChar - 1
            }

            return multiplier * 30
          },
          scaleY: isActive ? 0.8 : 1,
          autoAlpha: isActive ? 0 : 1,
          y: isActive ? 50 : 0,
        },
        {
          skewX: 0,
          scaleY: 1,
          y: 0,
          autoAlpha: isActive ? 1 : 0,
          duration: isActive ? 1.2 : PORTFOLIO_SINGLE_TRANSITION_OUT_DURATION,
          stagger: isActive ? 0.06 : 0,
          ease: 'Power3.easeOut',
        },
      )
    }
  }, [portfolioState, data])

  const handleBackClick = () => {
    setPortfolioState('TRANSITIONING_TO_MAIN')
  }

  if (!data) return null

  return (
    <section className={classnames(styles.PortfolioSingleContent, className)}>
      {data.teammateComments && !data.awards && (
        <div
          ref={commentsRef}
          className={styles.teammateCommentsModal}
        >
          <div className={styles.teammateCommentsModal__bg} />
          <p className={styles.teammateCommentsModal__text}>{data.teammateComments}</p>
        </div>
      )}
      {!isMobile && (
        <>
          <div
            ref={topRef}
            className={styles.top}
          >
            <div className={styles.topLeft}>
              <p className={styles.role}>Role: {data.role}</p>
              <p className={styles.agency}>Agency: {data.agency}</p>
            </div>
            <div className={styles.topCenter}>
              <button
                className={styles.back}
                onClick={handleBackClick}
                onMouseEnter={() => {
                  setHoveringBack(true)
                }}
                onMouseLeave={() => {
                  setHoveringBack(false)
                }}
              >
                <BackCircle active={hoveringBack} />
                <span className={styles.backText}>Back</span>
              </button>
            </div>
            {data.teammateComments && !data.awards && (
              <button
                onMouseEnter={() => {
                  setTeammateCommentsActive(true)
                }}
                onMouseLeave={() => {
                  setTeammateCommentsActive(false)
                }}
                className={styles.teammateCommentsButton}
              >
                Teammate Comments
              </button>
            )}
            {data.awards && <p className={styles.awards}>{data.awards}</p>}
          </div>
          <div
            ref={bottomRef}
            className={styles.bottom}
          >
            <div className={styles.bottomLeft}>
              <ScrollIndicator
                isActive={portfolioState === 'SINGLE'}
                className={styles.scrollIndicator}
                elementRef={scrollIndicatorRef}
              />
              <div>
                <p
                  ref={brandRef}
                  className={styles.brand}
                >
                  {data.brand}
                </p>
                <h1
                  ref={projectTitleRef}
                  className={styles.projectTitle}
                >
                  {data.title}
                </h1>
              </div>
            </div>
            <div
              className={styles.bottomRight}
              ref={bottomRightRef}
            >
              {data.link && (
                <a
                  href={data.link}
                  target="_blank"
                  className={styles.link}
                >
                  View Site
                </a>
              )}
            </div>
          </div>
        </>
      )}
      {isMobile && (
        <div
          className={styles.mobileContent}
          ref={mobileContentRef}
        >
          <button
            className={styles.mobileBack}
            onClick={handleBackClick}
          >
            <BackCircle />
          </button>
          <div className={styles.mobileTextContent}>
            <h1 className={styles.mobileContent__title}>{data.title}</h1>
            <p className={styles.mobileContent__brand}>Brand: {data.brand}</p>
            <p className={styles.mobileContent__role}>Role: {data.role}</p>
            <p className={styles.mobileContent__agency}>Agency: {data.agency}</p>
            <div className={styles.mobileContent__footer}>
              {data.teammateComments && !data.awards && (
                <button
                  onTouchStart={() => {
                    setTeammateCommentsActive(true)
                  }}
                  onTouchEnd={() => {
                    setTeammateCommentsActive(false)
                  }}
                  className={styles.mobileContent__teammateComments}
                >
                  Teammate Comments
                </button>
              )}
              {data.awards && <p className={styles.mobileContent__awards}>{data.awards}</p>}
              {data.link && (
                <a
                  href={data.link}
                  target="_blank"
                  className={styles.mobileContent__link}
                >
                  View Site
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

function BackCircle({ active }) {
  const caretRef = useRef()
  const circlePopRef = useRef()
  const circleOutlineRef = useRef()

  useEffect(() => {
    if (!caretRef.current || !circlePopRef.current || !circleOutlineRef.current) return

    gsap.killTweensOf([caretRef.current, circlePopRef.current, circleOutlineRef.current])

    const duration = 0.3

    gsap.to(caretRef.current, {
      color: active ? black : white,
      duration,
    })

    gsap.to(circlePopRef.current, {
      scale: active ? 1.1 : 0,
      ease: active ? 'back' : 'Power3.easeOut',
      duration,
    })
  }, [active])

  return (
    <span
      className={styles.backCaretCircle}
      ref={circleOutlineRef}
    >
      <div
        ref={circlePopRef}
        className={styles.backCaretCircleBg}
      />
      <div ref={caretRef}>
        <CaretUp className={styles.backCaret} />
      </div>
    </span>
  )
}

export default memo(PortfolioSingleContent)
