import Image from 'next/image'
import classnames from 'classnames'
import { useNextSanityImage } from 'next-sanity-image'

import { client as sanityClient } from '../../data-interface/sanity.js'

import styles from './SanityImage.module.scss'

/*
Breakpoint:
{
  breakpoint: number | 'default'
  image: sanityImage,
  width: number
}
*/

const SanityImage = ({ className, image, id, onLoad, sizes, breakpoints, imageOptions, unoptimized }) => {
  const imageProps = useNextSanityImage(sanityClient, image, {
    imageBuilder: (imageUrlBuilder, options) => {
      return imageUrlBuilder
        .width(options.width || Math.min(options.originalImageDimensions.width, 1920))
        .quality(options.quality || 75)
        .fit('clip')
        .saturation(imageOptions?.saturation ? -100 : undefined)
    },
  })

  const alt = image?.alt

  const imageElement = (
    <Image
      onLoadingComplete={e => {
        if (onLoad) onLoad(e)
      }}
      {...imageProps}
      sizes={sizes ? sizes : '75vw'}
      id={id}
      alt={alt ? alt : ''}
      placeholder="blur"
      blurDataURL={image.asset.metadata.lqip}
      className={classnames(className, styles.image)}
      priority
      unoptimized={unoptimized ? unoptimized : undefined}
    />
  )

  if (breakpoints) {
    return (
      <picture>
        {breakpoints?.map((item, index) => {
          if (!item.image || !item.width || !item.breakpoint) {
            return null
          }
          return (
            <source
              srcSet={getImageUrl(item.image, { width: item.width, ...item?.options })}
              media={`(min-width: ${item.breakpoint}px)`}
              key={index}
            />
          )
        })}
        {imageElement}
      </picture>
    )
  }

  if (!image) return null

  return imageElement
}

export const getImageUrl = (image, { width = null, quality = 80, invert = false, fm = 'webp', sat }) => {
  if (!image.asset.url) {
    console.warn('No image.asset.url in image object supplied. ', image)
    return null
  }

  let url = image.asset.url

  const params = []
  if (width) params.push(`w=${width}`)
  if (quality) params.push(`q=${quality}`)
  if (invert) params.push(`invert=${invert}`)
  if (fm) params.push(`fm=${fm}`)
  if (sat) params.push(`sat=${sat}`)

  url = `${url}?${params.join('&')}`

  return url
}

SanityImage.displayName = 'SanityImage'

export default SanityImage
