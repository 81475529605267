import { useProgress } from '@react-three/drei'
import { useRef } from 'react'
import { useEffect, useState } from 'react'
import useStore from 'store'
import useLoadAssets from 'utils/hooks/use-load-assets'

let TIMEOUT = 8000

export default function WebglLoadingProgress() {
  const progress = useProgress()
  const setPortfolioWebglImageLoaded = useStore(state => state.setPortfolioWebglImageLoaded)
  const setPortfolioLoadPercent = useStore(state => state.setPortfolioLoadPercent)
  const loaderAnimationComplete = useStore(state => state.loaderAnimationComplete)
  const setLoaderAnimationComplete = useStore(state => state.setLoaderAnimationComplete)
  const [totalImages, setTotalImages] = useState(0)
  const [imagesLoaded, setImagesLoaded] = useState(0)
  const [allImagesLoaded, setAllImagesLoaded] = useState(false)
  const [allVideosLoaded, setAllVideosLoaded] = useState(false)
  const [totalNonWebGlImagesLoaded, setTotalNonWebGlImagesLoaded] = useState(0)
  const timeoutRef = useRef()
  const { loadAssets: loadNonWebGLAssets, totalAssetsToLoad: totalNonWebGLAssetsToLoad } = useLoadAssets()

  useEffect(() => {
    if (totalNonWebGLAssetsToLoad === 0 || totalImages === 0) return

    const totalToLoad = totalImages + totalNonWebGLAssetsToLoad
    const totalThingsLoaded = imagesLoaded + totalNonWebGlImagesLoaded
    const totalPercentLoaded = totalThingsLoaded / totalToLoad

    /*
    console.log({
      toLoad: {
        count: totalToLoad,
        totalImages,
        totalNonWebGLAssetsToLoad,
      },
      loaded: {
        imagesLoaded,
        totalNonWebGlImagesLoaded,
        count: totalThingsLoaded,
      },
      percent: totalPercentLoaded,
    })
    */

    setPortfolioLoadPercent(totalPercentLoaded)
  }, [totalImages, imagesLoaded, totalNonWebGLAssetsToLoad, totalNonWebGlImagesLoaded])

  useEffect(() => {
    setTimeout(() => {
      loadNonWebGLAssets(null, progress => {
        setTotalNonWebGlImagesLoaded(progress.loaded)
      })
    }, 200)
  }, [loadNonWebGLAssets])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setAllImagesLoaded(true)
      setLoaderAnimationComplete(true)
    }, TIMEOUT)
  }, [setLoaderAnimationComplete])

  useEffect(() => {
    setTotalImages(progress.total)
  }, [progress.total])

  useEffect(() => {
    setImagesLoaded(progress.loaded)
  }, [progress.loaded])

  useEffect(() => {
    setTotalImages(progress.total)
  }, [progress.total])

  useEffect(() => {
    if (progress.progress !== 100) return
    setAllImagesLoaded(true)
  }, [progress.progress])

  useEffect(() => {
    if (!allImagesLoaded || !loaderAnimationComplete) return
    setTimeout(() => {
      setPortfolioWebglImageLoaded(true)
    }, 500)
  }, [allImagesLoaded, allVideosLoaded, setPortfolioWebglImageLoaded, loaderAnimationComplete])

  return null
}
