import { fields as assetFields } from "./asset";

export const fields = `
	_type,
  images[] {
    ${assetFields}
  }
`;

export const fragment = (name = "loader") => `${name}{ ${fields} }`;

export default fragment;
