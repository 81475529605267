import { Image, useVideoTexture } from '@react-three/drei'
import React from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import useNewKeyOnWindowResize from 'utils/hooks/use-new-key-on-window-resize'
import videoFragmentShader from './videoFragmentShader'

const DEFAULT_VIDEO_TEXTURE = 'https://nathandallaire.b-cdn.net/default-video-grey-optimized.mp4'

const WebGLVideo = React.forwardRef(
  (
    {
      visible,
      onPointerOver,
      onPointerOut,
      onClick,
      opacity,
      transparent,
      scale,
      toneMapped,
      imageSrc,
      element,
      assetType,
      scaleValues,
    },
    ref,
  ) => {
    const [src, setSrc] = useState(null)
    const [canPlayThrough, setCanPlayThrough] = useState(false)
    const videoTexture = useVideoTexture(src ? src : DEFAULT_VIDEO_TEXTURE, {
      start: false,
    })
    const videoRef = useRef()
    const key = useNewKeyOnWindowResize()
    const isReadyIntervalRef = useRef()

    useEffect(() => {
      if (visible) {
        setSrc(imageSrc)
      } else {
        if (videoTexture?.source?.data) {
          videoTexture?.source?.data.pause()
        }
        setCanPlayThrough(false)
        videoTexture.dispose()
        setSrc(null)
      }
    }, [visible, videoTexture])

    useEffect(() => {
      if (isReadyIntervalRef.current) {
        clearInterval(isReadyIntervalRef.current)
      }

      if (!visible || !videoTexture?.source?.data) return

      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
      isReadyIntervalRef.current = setInterval(() => {
        const isReady = videoTexture?.source?.data.readyState > 0

        console.log(videoTexture?.source?.data.readyState)
        if (isReady) {
          setCanPlayThrough(true)
          clearInterval(isReadyIntervalRef.current)
        }
      }, 100)

      return () => {
        if (isReadyIntervalRef.current) {
          clearInterval(isReadyIntervalRef.current)
        }
      }
    }, [visible, videoTexture])

    useEffect(() => {
      if (!canPlayThrough || !videoTexture?.source?.data) return
      videoTexture?.source?.data?.play()
    }, [canPlayThrough])

    useEffect(() => {
      const handleError = e => {
        console.log({ error: e.target.error, src: e.target.src })
      }

      if (videoRef.current) {
        videoRef.current.removeEventListener('error', handleError)
      }

      if (videoTexture?.source?.data) {
        videoRef.current = videoTexture?.source?.data
        videoRef.current.addEventListener('error', handleError)
      }

      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener('error', handleError)
        }
      }
    }, [videoTexture])

    // Ensuring video aspect ratio is not stretched or anything
    useEffect(() => {
      if (assetType !== 'video' || !ref.current || !scaleValues.width || !scaleValues.height) return

      const dimensionsData = element.dataset.videoDimensions.split('x')

      const videoDimensions = {
        w: parseInt(dimensionsData[0]),
        h: parseInt(dimensionsData[1]),
      }

      ref.current.material.uniforms.imageBounds.value = [1, 1]

      const canvasRatio = scaleValues.width / scaleValues.height
      const videoRatio = videoDimensions.w / videoDimensions.h
      const ratio = canvasRatio / videoRatio
      const width = videoDimensions.w * ratio

      ref.current.material.uniforms.scale.value = [width, videoDimensions.w]
    }, [assetType, element, key, scaleValues])

    // Ensure videos are proper colour
    useEffect(() => {
      if (!ref.current) return
      ref.current.material.fragmentShader = videoFragmentShader
      ref.current.material.needsUpdate = true
    }, [videoTexture])

    return (
      <Image
        visible={visible}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        onClick={onClick}
        opacity={opacity}
        transparent={transparent}
        scale={scale}
        toneMapped={toneMapped}
        ref={ref}
        texture={videoTexture}
      />
    )
  },
)

export default WebGLVideo
