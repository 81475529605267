import { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import styles from './Cursor.module.scss'
import useStore from 'store'
import { useRouter } from 'next/router'
import { lerp } from 'utils'
import { getIsTouchDevice } from 'utils/detect.js'
import CaretUp from 'assets/svgs/caret-up.svg'
import gsap from 'gsap'
import useBreakpoint from 'utils/hooks/use-breakpoint'

export const CURSOR_TYPES = {
  HOVER: 'HOVER',
  GRAB: 'GRAB',
}

const LERP_LEVEL = 0.18

const Cursor = ({ className }) => {
  const [showCursor, setShowCursor] = useState(false)
  const cursorType = useStore(state => state.cursorType)
  const setCursorType = useStore(state => state.setCursorType)
  const containerRef = useRef()
  const targetPosRef = useRef({ x: 0, y: 0 })
  const currentPostRef = useRef({ x: 0, y: 0 })
  const rafRef = useRef()
  const scaleValue = useRef(0)
  const { isMobile } = useBreakpoint()
  const router = useRouter()

  const raf = () => {
    rafRef.current = requestAnimationFrame(raf)
    currentPostRef.current = {
      x: lerp(currentPostRef.current.x, targetPosRef.current.x, LERP_LEVEL),
      y: lerp(currentPostRef.current.y, targetPosRef.current.y, LERP_LEVEL),
    }

    gsap.set(containerRef.current, {
      x: currentPostRef.current.x - containerRef.current.offsetWidth * 0.5,
      y: currentPostRef.current.y - containerRef.current.offsetHeight * 0.5,
      scale: scaleValue.current,
    })
  }

  const animateInit = () => {
    gsap.set(containerRef.current, {
      scale: 0,
    })
  }

  useEffect(() => {
    animateInit()
  }, [])

  useEffect(() => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current)
    }

    if (!isMobile) {
      raf()
    }

    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current)
      }
    }
  }, [isMobile])

  useEffect(() => {
    setCursorType(null)
  }, [router.asPath])

  useEffect(() => {
    if (isMobile) return

    const isPointer = cursorType === CURSOR_TYPES.HOVER || cursorType === CURSOR_TYPES.GRAB

    document.body.dataset.cursor = cursorType?.toLowerCase()

    gsap.killTweensOf(scaleValue)

    gsap.to(scaleValue, {
      current: isPointer ? 1 : 0,
      ease: 'Power3.easeOut',
      duration: 0.45,
    })
  }, [cursorType, isMobile])

  useEffect(() => {
    const handleMouseOver = e => {
      if (!containerRef.current) return

      const x = e.changedTouches?.length ? e.changedTouches[0]?.clientX : e.clientX
      const y = e.changedTouches?.length ? e.changedTouches[0]?.clientY : e.clientY

      targetPosRef.current = { x, y }
    }

    document.removeEventListener('mousemove', handleMouseOver)

    if (!getIsTouchDevice()) {
      document.addEventListener('mousemove', handleMouseOver)
    }

    return () => {
      if (!getIsTouchDevice()) {
        document.removeEventListener('mousemove', handleMouseOver)
      }
    }
  }, [isMobile])

  return (
    <div
      className={classnames(styles.Cursor, className, { [styles.showCursor]: showCursor })}
      ref={containerRef}
    >
      {cursorType === CURSOR_TYPES.HOVER && <CaretUp className={styles.caretIcon} />}
      {cursorType === CURSOR_TYPES.GRAB && <span className={styles.grabText}>Grab</span>}
    </div>
  )
}

Cursor.displayName = 'Cursor'

export default Cursor
