import React, { memo, useEffect } from 'react'
import classnames from 'classnames'
import styles from './PortfolioScrollImagesSingle.module.scss'
import useStore from 'store'
import { useRef } from 'react'
import { useState } from 'react'
import { getImageUrl } from 'components/SanityImage/SanityImage'
import useBreakpoint from 'utils/hooks/use-breakpoint'

export const getListId = index => `single-list-${index}`
export const MAIN_IMAGE_ID = 'single-list-main'
export const SINGLE_IMAGE_CLASS_NAME = styles.image
export const SINGLE_LIST_CLASS_NAME = styles.PortfolioScrollImagesSingle

const MAIN_CLASS_NAME = styles.isMain

function PortfolioScrollImagesSingle({ images, mainImage, index, className, brand }) {
  const activePortfolioItemIndex = useStore(state => state.activePortfolioItemIndex)
  const containerRef = useRef()
  const [offsetX, setOffsetX] = useState('0px')
  const { isMobile } = useBreakpoint()

  if (!images?.length) return null

  const items = [mainImage, ...images]

  let mainIndex = 0
  const sortedItems = []
  for (let index = 0; index < items.length; index++) {
    const appendItem = items[index]
    const prependItem = items[items.length - (index + 1)]

    if (sortedItems.length !== items.length) {
      sortedItems.push(appendItem)
    }

    if (sortedItems.length !== items.length) {
      mainIndex++
      sortedItems.unshift(prependItem)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (!containerRef.current) return
      const mainImageElement = containerRef.current.querySelectorAll(`.${MAIN_CLASS_NAME}`)[0]
      if (!mainImageElement) return
      const fromCenter = mainImageElement.offsetLeft + mainImageElement.offsetWidth * 0.5
      setOffsetX(`-${fromCenter}px`)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // if (brand !== 'Google') return null

  if (!images?.length) return null

  return (
    <ul
      className={classnames(SINGLE_LIST_CLASS_NAME, className, { [styles.isEven]: sortedItems.length % 2 === 0 })}
      id={getListId(index)}
      data-index={index}
      data-offset={offsetX}
      ref={containerRef}
      style={{ '--offset-x': offsetX }}
    >
      {sortedItems.map((item, i) => {
        const isMain = mainIndex === i

        let src = item?.itemType === 'image' ? getImageUrl(item?.image, { width: 1000 }) : item?.videoURL
        if (isMain) src = item.asset.url

        if (item?.itemType === 'video' && isMobile && item?.videoURLMobile) {
          src = item?.videoURLMobile
        }

        if (item?.itemType === 'image' && isMobile) {
          src = getImageUrl(item?.image, { width: 500, quality: 75 })
        }

        let ratio = 1

        if (item?.itemType === 'image') {
          ratio = item.image.asset.metadata.dimensions.width / item.image.asset.metadata.dimensions.height
        }

        if (item?.itemType === 'video') {
          const videoDimensions = item?.videoDimensions.split('x')
          ratio = parseInt(videoDimensions[0]) / parseInt(videoDimensions[1])
        }

        if (isMain) ratio = 1.4

        return (
          <li
            className={classnames(styles.item, { [MAIN_CLASS_NAME]: isMain })}
            key={i}
            style={{ '--aspect-ratio': ratio }}
          >
            <img
              data-ratio={ratio}
              data-video-dimensions={item?.videoDimensions ? item?.videoDimensions : undefined}
              data-src={src}
              data-type={item.itemType}
              data-gallery-length={sortedItems.length}
              alt=""
              className={SINGLE_IMAGE_CLASS_NAME}
              data-is-main={mainIndex === i}
              id={mainIndex === i && index === activePortfolioItemIndex ? MAIN_IMAGE_ID : undefined}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default memo(PortfolioScrollImagesSingle)
