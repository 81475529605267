import { fields as linkFields } from "./link";

export const fields = `
	_type,
	_key,
	content[] {
		_key,
		_type,
		children,
		level,
		listItem,
		style,
		markDefs[] {
			_type == "link" => {
				${linkFields}
			},
			_key,
			_type,
		}
	}
`;
/*

*/

export const fragment = (name = "richText") => `${name}{${fields}}`;

export default fragment;
