import React, { useEffect, useRef } from 'react'
import { EffectComposer } from '@react-three/postprocessing'
import HorizontalDistortion from './HorizontalDistortion'
import VerticalDistortion from './VerticalDistortion'
import useStore from 'store'
import { useFrame } from '@react-three/fiber'
import { lerp } from 'utils'
import gsap from 'gsap'

import { PORTFOLIO_SINGLE_TRANSITION_OUT_DURATION, PORTFOLIO_TRANSITION_DURATION } from 'data'
import useBreakpoint from 'utils/hooks/use-breakpoint'
import useTransitionState from 'utils/hooks/use-transition-state'

export default function Effects() {
  const composer = useRef()
  const subRef = useRef()
  const scrollDelta = useRef({
    current: 0,
    target: 0,
    last: 0,
    offset: 0,
  })
  const portfolioState = useStore(state => state.portfolioState)
  const portfolioWebglImageLoaded = useStore(state => state.portfolioWebglImageLoaded)
  const transitionStrength = useRef(0)
  const isTransitioning = portfolioState === 'TRANSITIONING_TO_SINGLE' || portfolioState === 'TRANSITIONING_TO_MAIN'
  const hasIntroAnimated = useRef(false)
  const { isMobile } = useBreakpoint()
  const verticalDistortionRef = useRef()
  const horizontalDistortionRef = useRef()
  const isAnimatingDeltaValues = useRef(false)
  const portfolioStateRef = useRef()
  const { isPortfolio } = useTransitionState()

  useFrame(() => {
    scrollDelta.current.current = lerp(scrollDelta.current.current, scrollDelta.current.target, 0.05)

    let strength = ((scrollDelta.current.current - scrollDelta.current.last) / window.innerWidth) * 50

    if (isMobile) {
      if (portfolioState === 'SINGLE') {
        strength = strength * 1.5
      } else {
        strength = strength * 2
      }
    }

    if (portfolioState !== 'SINGLE') {
      strength = Math.abs(strength)
    }

    if (portfolioState === 'SINGLE') {
      strength = Math.abs(strength) * -1
    }

    strength += transitionStrength.current

    if (portfolioState === 'SINGLE' && verticalDistortionRef.current) {
      verticalDistortionRef.current.uniforms.get('strength').value = strength
    }

    if (portfolioState !== 'SINGLE' && horizontalDistortionRef.current) {
      horizontalDistortionRef.current.uniforms.get('strength').value = strength
    }

    scrollDelta.current.last = scrollDelta.current.current
  })

  useEffect(() => {
    if (subRef.current) {
      subRef.current()
    }

    subRef.current = useStore.subscribe(
      state => [state.portfolioScrollDistance, state.portfolioState],
      state => {
        const distance = state[0]
        if (!state[1].toLowerCase().includes('transitioning')) {
          isAnimatingDeltaValues.current = false

          if (portfolioStateRef.current === 'TRANSITIONING_TO_MAIN' && state[1] === 'MAIN') {
            scrollDelta.current.offset = distance
          }

          if (state[1] === 'SINGLE' || state[1] === 'TRANSITIONING_TO_MAIN') {
            scrollDelta.current.target = distance
          } else {
            scrollDelta.current.target = distance - scrollDelta.current.offset
          }
        } else {
          if (!isAnimatingDeltaValues.current) {
            isAnimatingDeltaValues.current = true

            gsap.to(scrollDelta.current, {
              last: 0,
              current: 0,
              target: 0,
              duration: 0.3,
            })
          }
        }

        portfolioStateRef.current = state[1]
      },
    )

    return () => {
      if (subRef.current) {
        subRef.current()
      }
    }
  }, [])

  useEffect(() => {
    if (!portfolioWebglImageLoaded || !isPortfolio) return

    if (portfolioState === 'TRANSITIONING_TO_MAIN' || !hasIntroAnimated.current) {
      gsap.to(transitionStrength, {
        current: 1.5,
        duration: 0,
        ease: 'Power2.easeInOut',
        delay: !hasIntroAnimated.current ? 0 : PORTFOLIO_SINGLE_TRANSITION_OUT_DURATION,
        onComplete: () => {
          hasIntroAnimated.current = true
          gsap.to(transitionStrength, {
            current: 0,
            duration: PORTFOLIO_TRANSITION_DURATION * 2.5,
            ease: 'Power4.easeOut',
          })
        },
      })
    }
  }, [isTransitioning, portfolioWebglImageLoaded, isPortfolio])

  useEffect(() => {
    if (isPortfolio) return
    hasIntroAnimated.current = false
  }, [isPortfolio])

  return (
    <EffectComposer ref={composer}>
      <VerticalDistortion ref={verticalDistortionRef} />
      {portfolioState !== 'SINGLE' && <HorizontalDistortion ref={horizontalDistortionRef} />}
      {/* <ChromaticAberration offset={[0.02, 0.002]} /> */}
    </EffectComposer>
  )
}
