/* INJECT_SECTIONS_IMPORT */
import PortfolioItems from 'components/PortfolioItems/PortfolioItems'
import AboutContent from 'components/AboutContent/AboutContent'

const MODULES = {
  /* INJECT_SECTIONS_TYPE */
  portfolioItems: PortfolioItems,
  aboutContent: AboutContent,
}

function Sections({ modules }) {
  if (!modules?.length) return null

  return (
    <>
      {modules.map((moduleArr, i) => {
        if (!moduleArr?.module) {
          console.warn('No modules tied to data ', moduleArr)
          return null
        }

        const moduleObj = moduleArr?.module[0]

        if (!MODULES[moduleObj._type]) {
          console.warn('No module found with type ', moduleObj._type)
          return null
        }

        const ModuleComponent = MODULES[moduleObj._type]

        return (
          <ModuleComponent
            key={`${i}_${moduleObj._key}`}
            {...moduleObj}
          />
        )
      })}
    </>
  )
}

export default Sections
