import React, { memo, useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import classnames from 'classnames'
import NormalizeWheel from 'normalize-wheel'
import styles from './PortfolioScrollText.module.scss'
import SplitText, { letterClass } from 'utils/splitText'
import { getSortedGalleryItems, lerp } from 'utils'
import useStore from 'store'
import useNewKeyOnWindowResize from 'utils/hooks/use-new-key-on-window-resize'
import ScrollIndicator from '../ScrollIndicator/ScrollIndicator'
import { PORTFOLIO_TRANSITION_DURATION, PAGE_TRANSITION_DURATION } from 'data'
import useBreakpoint from 'utils/hooks/use-breakpoint'
import useTransitionState from 'utils/hooks/use-transition-state'

export const BRAND_TITLE_CLASS = styles.PortfolioScrollItem__brandTitleContainer

const LERP_LEVEL = 0.06

const roundNearest = (value, nearest) => Math.round(value / nearest) * nearest

function PortfolioScrollItem({ className, items, isActive }) {
  const { isPortfolio } = useTransitionState()
  const brandRef = useRef()
  const [splitComplete, setSplitComplete] = useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)
  const brandContainerInnerRef = useRef()
  const brandTitles = useRef([])
  const height = useRef(0)
  const rafRef = useRef()
  const scrolled = useRef(0)
  const currentScrolled = useRef(0)
  const direction = useRef(null)
  const titlePositionOffsets = useRef({})
  const prevScroll = useRef(0)
  const scrollIndicatorRef = useRef()
  const hasAnimatedIn = useRef(false)
  const touchStart = useRef(0)
  const touchDown = useRef(false)
  const dragDistance = useRef(0)
  const tempScrolled = useRef(0)
  const setPortfolioScrollDistance = useStore(state => state.setPortfolioScrollDistance)
  const setPortfolioScrollDelta = useStore(state => state.setPortfolioScrollDelta)
  const portfolioWebglImageLoaded = useStore(state => state.portfolioWebglImageLoaded)
  const setPortfolioScrollDirection = useStore(state => state.setPortfolioScrollDirection)
  const setActivePortfolioItemIndex = useStore(state => state.setActivePortfolioItemIndex)
  const setPortfolioState = useStore(state => state.setPortfolioState)
  const activePortfolioItemIndex = useStore(state => state.activePortfolioItemIndex)
  const portfolioState = useStore(state => state.portfolioState)
  const canInteract = useStore(state => state.canInteract)
  const isEven = items.length % 2 === 0
  const key = useNewKeyOnWindowResize()
  const { isMobile } = useBreakpoint()
  const mobileHeaderOpen = useStore(state => state.mobileHeaderOpen)
  const mobileHeaderOpenRef = useRef(false)
  const mobileOverlayRef = useRef()
  const [activeScrolledIndex, setActiveScrolledIndex] = useState(
    isEven ? Math.floor(items.length * 0.5) : Math.floor(items.length * 0.5),
  )
  const previousActiveScrolledIndex = useRef(0)
  const mobileRefs = useRef({
    masterContainer: null,
    containers: {},
    titles: {},
    brands: {},
  })

  useEffect(() => {
    mobileHeaderOpenRef.current = mobileHeaderOpen
  }, [mobileHeaderOpen])

  const getScrollElementHeight = () => brandTitles.current[0].offsetHeight

  const handleClick = i => {
    if (portfolioState !== 'MAIN' || isMobile) return
    setActivePortfolioItemIndex(i)
    setPortfolioState('TRANSITIONING_TO_SINGLE')
  }

  const raf = storeRef => {
    if (storeRef) {
      rafRef.current = requestAnimationFrame(raf)
    }

    if (storeRef) {
      currentScrolled.current = lerp(currentScrolled.current, scrolled.current, LERP_LEVEL)
    } else {
      currentScrolled.current = scrolled.current
    }

    brandTitles.current.forEach((container, i) => {
      if (!container) return

      const elementHeight = container?.offsetHeight

      // scroll
      const offset = container.offsetHeight * i
      titlePositionOffsets.current[i] = currentScrolled.current + offset
      let delta = (titlePositionOffsets.current[i] + height.current) % height.current

      if (delta < 0) {
        delta = height.current + delta
      }

      gsap.set(container, {
        y: delta,
      })

      // Alpha
      const parentPos = brandContainerInnerRef?.current?.getBoundingClientRect()
      const newChildPos = container?.getBoundingClientRect()
      const evenOddOffset = isEven ? elementHeight * 0.5 : 0
      const alphaTop = newChildPos.top - (parentPos.top + height.current * 0.5 - elementHeight * 0.5 + evenOddOffset)

      const heightMultiplier = isMobile ? 1.5 : 2.5

      const alpha = 1 - Math.abs(alphaTop / (elementHeight * heightMultiplier))
      const isActive = alpha > 0

      gsap.set(container, {
        autoAlpha: alpha,
        pointerEvents: isActive ? 'all' : 'none',
      })
    })
  }

  useEffect(() => {
    const handleOnWheel = event => {
      if (!canInteract || mobileHeaderOpenRef.current) return

      const normalized = NormalizeWheel(event)
      let speed = normalized.pixelY * 0.07

      scrolled.current += speed
      direction.current = speed > 0 ? 'down' : 'up'

      // Set in store
      setPortfolioScrollDistance(scrolled.current)
      setPortfolioScrollDirection(direction.current)

      // Velocity
      let delta = Math.max(0, Math.abs(speed) - prevScroll.current)

      setPortfolioScrollDelta(delta)

      prevScroll.current = Math.abs(speed)
    }

    const onTouchDown = event => {
      if (mobileHeaderOpenRef.current) return
      touchDown.current = true
      touchStart.current = event.touches ? -event.touches[0].clientY : -event.clientY

      if (portfolioState === 'SINGLE') {
        touchStart.current = event.touches ? event.touches[0].clientX : event.clientX
      }

      tempScrolled.current = scrolled.current
    }

    const onTouchMove = event => {
      if (!touchDown.current) return
      if (mobileHeaderOpenRef.current) return

      let value = event.touches ? -event.touches[0].clientY : -event.clientY

      if (portfolioState === 'SINGLE') {
        value = event.touches ? event.touches[0].clientX : event.clientX
      }

      let distance = (touchStart.current - value) * 0.5
      if (portfolioState === 'SINGLE') {
        distance = distance * 1.2
      }

      const delta = Math.abs(tempScrolled.current - (tempScrolled.current + distance))

      dragDistance.current = tempScrolled.current + distance
      direction.current = dragDistance.current - tempScrolled.current > 0 ? 'down' : 'up'
      scrolled.current = dragDistance.current

      setPortfolioScrollDistance(scrolled.current)
      setPortfolioScrollDirection(direction.current)
      setPortfolioScrollDelta(delta)
    }

    const onTouchUp = () => {
      if (mobileHeaderOpenRef.current) return

      touchDown.current = false
      dragDistance.current = 0
      scrolled.current = scrolled.current + dragDistance.current
      tempScrolled.current = 0
      setPortfolioScrollDelta(0)

      if (portfolioState === 'MAIN') {
        const elementHeight = getScrollElementHeight()
        const itemOffset = isEven
          ? Math.round(elementHeight * items.length * 0.5)
          : Math.round(elementHeight * items.length * 0.5 - elementHeight * 0.5)
        const rounded = Math.round((scrolled.current - itemOffset) / elementHeight)

        let mod = rounded % items.length
        if (mod > 0) {
          mod = items.length - mod
        } else {
          mod = Math.abs(mod)
        }

        setActiveScrolledIndex(mod)
      }
    }

    if (portfolioState === 'TRANSITIONING_TO_SINGLE') {
      setTimeout(() => {
        scrolled.current = 0
        setPortfolioScrollDistance(scrolled.current)
      }, 100)
    }

    if (!canInteract || !isPortfolio) return

    window.removeEventListener('touchstart', onTouchDown)
    window.removeEventListener('touchmove', onTouchMove)
    window.removeEventListener('touchend', onTouchUp)
    window.removeEventListener('mousewheel', handleOnWheel)
    window.removeEventListener('wheel', handleOnWheel)

    window.addEventListener('touchstart', onTouchDown)
    window.addEventListener('touchmove', onTouchMove)
    window.addEventListener('touchend', onTouchUp)
    window.addEventListener('mousewheel', handleOnWheel)
    window.addEventListener('wheel', handleOnWheel)

    return () => {
      window.removeEventListener('touchstart', onTouchDown)
      window.removeEventListener('touchmove', onTouchMove)
      window.removeEventListener('touchend', onTouchUp)
      window.removeEventListener('mousewheel', handleOnWheel)
      window.removeEventListener('wheel', handleOnWheel)
    }
  }, [setPortfolioScrollDistance, key, canInteract, portfolioState])

  /* ========================================
  // Animating mobile values on scroll
  ======================================== */
  useEffect(() => {
    if (!isMobile || portfolioState === 'SINGLE' || !portfolioWebglImageLoaded || !isPortfolio) return

    const allItems = [
      ...Object.values(mobileRefs.current.containers),
      ...Object.values(mobileRefs.current.titles),
      ...Object.values(mobileRefs.current.brands),
    ]

    const isActive = portfolioState === 'MAIN'

    gsap.to(mobileRefs.current.masterContainer, {
      autoAlpha: isActive ? 1 : 0,
      duration: 0.6,
      delay: 0.4,
    })

    gsap.killTweensOf(allItems)

    const skewValue = -10

    const previousIndex = previousActiveScrolledIndex.current
    const peviousItems = {
      container: mobileRefs.current.containers[previousIndex],
      title: mobileRefs.current.titles[previousIndex],
      brand: mobileRefs.current.brands[previousIndex],
    }

    const ease = 'Power3.easeOut'
    const outDuration = 0.5

    // iNitial setup with skew stuff
    // because apparently css dont wanna play
    if (!hasAnimatedIn.current) {
      for (let index = 0; index < items.length; index++) {
        if (index !== activeScrolledIndex) {
          gsap.set(mobileRefs.current.titles[index], {
            skewY: skewValue,
          })
        }
      }
    }

    if (peviousItems.container && peviousItems.title && peviousItems.brand) {
      gsap.set(peviousItems.container, {
        pointerEvents: 'none',
      })
      gsap.to(peviousItems.title, {
        y: '-160%',
        skewY: skewValue,
        ease,
        duration: outDuration,
      })
      gsap.to(peviousItems.brand, {
        y: '160%',
        // skewY: 10,
        ease,
        duration: outDuration,
      })
    }

    const currentItems = {
      container: mobileRefs.current.containers[activeScrolledIndex],
      title: mobileRefs.current.titles[activeScrolledIndex],
      brand: mobileRefs.current.brands[activeScrolledIndex],
    }

    const inDuration = 0.8

    if (currentItems.container && currentItems.title && currentItems.brand) {
      gsap.set(currentItems.container, {
        pointerEvents: isActive ? 'all' : 'none',
      })

      gsap.to(currentItems.title, {
        y: isActive ? 0 : '-160%',
        skewY: isActive ? 1 : skewValue,
        ease,
        duration: inDuration,
      })

      gsap.to(currentItems.brand, {
        y: isActive ? 0 : '160%',
        // skewY: 1,
        ease,
        duration: inDuration,
      })
    }

    if (activeScrolledIndex !== null) {
      previousActiveScrolledIndex.current = activeScrolledIndex
    }
  }, [isMobile, activeScrolledIndex, portfolioState, portfolioWebglImageLoaded, isPortfolio])

  useEffect(() => {
    if (portfolioState !== 'TRANSITIONING_TO_MAIN' || activePortfolioItemIndex === null) return

    const elementHeight = getScrollElementHeight()
    const toScroll = height.current * 0.5 - elementHeight * activePortfolioItemIndex

    setTimeout(() => {
      scrolled.current = toScroll
      setPortfolioScrollDistance(toScroll)
      setActiveScrolledIndex(activePortfolioItemIndex)

      setTimeout(() => {
        raf()
      }, 50)
    }, 100)
  }, [portfolioState, activePortfolioItemIndex, isMobile])

  // When portfolio item is active
  useEffect(() => {
    if (!scrollIndicatorRef.current || !brandContainerInnerRef.current || !hasAnimatedIn.current) return

    const isActive = portfolioState === 'MAIN'

    const generalConfig = {
      autoAlpha: isActive ? 1 : 0,
      ease: 'Power3.easeInOut',
      duration: PORTFOLIO_TRANSITION_DURATION / 2,
      pointerEvents: isActive ? 'all' : 'none',
    }

    gsap.killTweensOf(brandContainerInnerRef.current)
    gsap.to(brandContainerInnerRef.current, {
      ...generalConfig,
      x: isActive ? 0 : '-130%',
    })

    gsap.killTweensOf(scrollIndicatorRef.current)
    gsap.to(scrollIndicatorRef.current, {
      ...generalConfig,
      x: isActive ? 0 : '130%',
    })
  }, [portfolioState])

  useEffect(() => {
    SplitText.init(brandRef.current)
    setSplitComplete(true)
  }, [])

  useEffect(() => {
    if (!portfolioWebglImageLoaded || !isPortfolio) return

    const duration = 0.8
    const delay = 0.8

    gsap.fromTo(
      brandContainerInnerRef.current,
      {
        autoAlpha: 0,
        x: -200,
      },
      {
        autoAlpha: 1,
        x: 0,
        duration,
        delay,
      },
    )

    gsap.fromTo(
      scrollIndicatorRef.current,
      {
        autoAlpha: 0,
        x: scrollIndicatorRef.current.offsetWidth,
      },
      {
        autoAlpha: 1,
        x: 0,
        duration,
        delay,
      },
    )

    gsap.to(mobileOverlayRef.current, {
      autoAlpha: 1,
      delay: PAGE_TRANSITION_DURATION,
    })

    if (!hasAnimatedIn.current) {
      setTimeout(() => {
        raf()
      }, 50)
    }

    setTimeout(() => {
      hasAnimatedIn.current = true
    }, (duration * 1000 + delay * 1000) * 0.7)
  }, [portfolioWebglImageLoaded, isPortfolio])

  useEffect(() => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current)
    }

    if (canInteract && !isMobile) {
      raf(true)
    }

    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current)
      }
    }
  }, [portfolioState, canInteract, isMobile])

  useEffect(() => {
    if (!brandTitles.current.length) return

    let totalHeight = 0
    brandTitles.current.forEach((element, i) => {
      const height = element.offsetHeight
      totalHeight += height
      const offset = i * height
      gsap.set(element, {
        y: offset,
      })
    })

    height.current = totalHeight
  }, [key])

  return (
    <>
      <ScrollIndicator
        elementRef={scrollIndicatorRef}
        className={styles.scrollIndicator}
        isActive={portfolioState !== 'SINGLE'}
      />
      <div
        className={styles.mobileOverlay}
        ref={mobileOverlayRef}
      />
      <div
        className={classnames(
          styles.PortfolioScrollItem,
          className,
          {
            [styles.isActive]: isActive,
          },
          {
            [styles.splitComplete]: splitComplete,
          },
          {
            [styles.isEven]: items.length % 2 === 0,
          },
        )}
        style={{ '--count': items.length, '--count-half': Math.ceil(items.length) }}
      >
        <div className={styles.brandContainer}>
          <div
            className={styles.brandContainerInner}
            ref={brandContainerInnerRef}
          >
            {items.map((item, i) => (
              <div
                key={i}
                className={classnames(BRAND_TITLE_CLASS, { [styles.isActive]: activeIndex === i })}
                ref={ref => {
                  brandTitles.current[i] = ref
                }}
              >
                <h1
                  ref={ref => {
                    if (i === 0) {
                      brandRef.current = ref
                    }
                  }}
                  className={styles.lowerInfo__brand}
                  onClick={() => {
                    handleClick(i)
                  }}
                >
                  {item.title}
                </h1>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={styles.mobileTitles}
        ref={ref => {
          mobileRefs.current.masterContainer = ref
        }}
      >
        {items.map((item, i) => {
          return (
            <div
              className={styles.mobileTitle}
              key={i}
              ref={ref => {
                mobileRefs.current.containers[i] = ref
              }}
            >
              <h1
                ref={ref => {
                  mobileRefs.current.titles[i] = ref
                }}
                className={styles.mobileTitle__title}
              >
                {item.title}
              </h1>
              <p
                ref={ref => {
                  mobileRefs.current.brands[i] = ref
                }}
                className={styles.mobileTitle__brand}
              >
                {item.brand}
              </p>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default memo(PortfolioScrollItem)
