import link, { fields as linkFields } from "./link";

export const fields = `
	_type,
  descriptionLine1,
  descriptionLine2,
  ${link("logoLink")},
  ${link("ticketLink")},
  navLinks[] {
    ${linkFields}
  },
  footerLinks[] {
    ${linkFields}
  },
  countdownEndDate,
  countdownText,
  appDownloadText,
  ${link("appStoreLink")},
  ${link("googlePlayLink")},
  navigationLeftSideText,
  ${link("instagramLink")},
  ${link("twitterLink")}
`;

export const fragment = (name = "header") => `${name}{ ${fields} }`;

export default fragment;
