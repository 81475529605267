import React, { memo, useState, useEffect } from 'react'
import classnames from 'classnames'
import AboutImages from './AboutImages/AboutImages'
import styles from './AboutContent.module.scss'
import { useRef } from 'react'
import useTransitionState from 'utils/hooks/use-transition-state'
import gsap from 'gsap'
import { PAGE_TRANSITION_DURATION_MS } from 'data'
import useStore from 'store'
import Background from 'components/Background/Background'
import AboutRotator from './AboutRotator/AboutRotator'
import useBreakpoint from 'utils/hooks/use-breakpoint'

function AboutContent({ className, bio, images }) {
  const containerRef = useRef()
  const { isAbout } = useTransitionState()
  const [isHidden, setIsHidden] = useState(false)
  const portfolioWebglImageLoaded = useStore(state => state.portfolioWebglImageLoaded)
  const pageIsActive = portfolioWebglImageLoaded && isAbout
  const hiddenTimeoutRef = useRef(null)
  const { isMobile } = useBreakpoint()

  const elements = useRef({
    messageMeLine1: null,
    messageMeLine2: null,
    rotator: null,
    bio,
  })

  /* ============================================
  Fading in/out about on page transition
  ============================================ */
  useEffect(() => {
    if (!portfolioWebglImageLoaded) return

    if (hiddenTimeoutRef.current) {
      clearTimeout(hiddenTimeoutRef.current)
    }

    if (isAbout) {
      setIsHidden(false)
    } else {
      hiddenTimeoutRef.current = setTimeout(() => {
        setIsHidden(true)
      }, PAGE_TRANSITION_DURATION_MS)
    }
  }, [isAbout, portfolioWebglImageLoaded])

  /* ============================================
  animating in/out
  ============================================ */
  useEffect(() => {
    if (!pageIsActive) return

    setTimeout(() => {
      if (
        !elements.current.messageMeLine1 ||
        !elements.current.messageMeLine2 ||
        !elements.current.rotator ||
        !elements.current.bio
      ) {
        return
      }

      const duration = 0.8
      const delay = 0.8
      const ease = 'Power3.easeOut'

      let order = [
        elements.current.messageMeLine1,
        elements.current.messageMeLine2,
        elements.current.rotator,
        elements.current.bio,
      ]

      if (isMobile) {
        order = [
          elements.current.rotator,
          elements.current.bio,
          elements.current.messageMeLine1,
          elements.current.messageMeLine2,
        ]
      }

      gsap.fromTo(
        order,
        {
          y: 40,
        },
        {
          autoAlpha: 1,
          y: 0,
          ease,
          delay,
          duration,
          stagger: 0.1,
        },
      )
    }, 60)
  }, [pageIsActive])

  useEffect(() => {
    if (!isMobile || !elements.current.rotator) return

    gsap.set(elements.current.rotator, {
      clearProps: 'transform',
    })
  }, [isMobile])

  if (isHidden) return null

  if (!bio || !images?.length) return null

  return (
    <>
      <div
        ref={containerRef}
        className={classnames(styles.AboutContent, className, { [styles.active]: isAbout })}
      >
        <AboutRotator
          className={styles.rotatorContainer}
          ref={ref => {
            elements.current.rotator = ref
          }}
        />
        <AboutImages images={images} />
        <div
          className={styles.bioContainer}
          ref={ref => {
            elements.current.bio = ref
          }}
        >
          <p className={styles.bioContainer__bio}>{bio}</p>
        </div>
        <div className={styles.messageMeContainer}>
          <p
            className={styles.messageMeContainer__text}
            ref={ref => {
              elements.current.messageMeLine1 = ref
            }}
          >
            I love to collaborate and keep busy. Have something cool to work on?{' '}
            <a
              href="mailto:nathan@dallaire.io"
              className={styles.messageMeContainer__link}
            >
              Message me
            </a>
            .
          </p>
          <p
            ref={ref => {
              elements.current.messageMeLine2 = ref
            }}
            className={styles.messageMeContainer__text}
          >
            <a href="mailto:nathan@dallaire.io">nathan@dallaire.io</a>
          </p>
        </div>
      </div>
      <Background
        className={classnames(styles.background, { [styles.active]: pageIsActive })}
        theme="light"
        active={pageIsActive}
      />
    </>
  )
}

export default memo(AboutContent)
