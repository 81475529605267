import { mobile, tablet, laptop, desktop, xl } from '../styles/export-vars.module.scss';

const map = {
  mobile,
  tablet,
  laptop,
  desktop,
  xl
};

const breakpoints = Object.keys(map).map((key) => {
  return { name: key, width: parseInt(map[key]) };
});

export default breakpoints;
