import { Canvas } from '@react-three/fiber'

import styles from './WebGL.module.scss'
import Effects from './Effects'
import PortfolioItemsMain from './Elements/PortfolioItemsMain'
import PortfolioItemsSingle from './Elements/PortfolioItemsSingle'
import { useState } from 'react'
import { PerformanceMonitor } from '@react-three/drei'
import { Suspense } from 'react'
import Progress from './LoadingProgress'
import useTransitionState from 'utils/hooks/use-transition-state'
import classnames from 'classnames'
import useStore from 'store'

const WebGL = () => {
  const [dpr, setDpr] = useState(1.5)
  const { isPortfolio } = useTransitionState()
  const portfolioWebglImageLoaded = useStore(state => state.portfolioWebglImageLoaded)
  const pageIsActive = portfolioWebglImageLoaded && isPortfolio

  return (
    <div className={classnames(styles.WebGL, { [styles.active]: pageIsActive })}>
      <Progress />
      <Canvas
        frameloop={pageIsActive ? 'always' : 'never'}
        mode="concurrent"
        dpr={dpr}
        orthographic
        camera={{ position: [0, 0, 100] }}
      >
        <PerformanceMonitor
          onIncline={() => setDpr(2)}
          onDecline={() => setDpr(1)}
        />
        <Effects />
        <PortfolioItemsMain />
        <PortfolioItemsSingle />
      </Canvas>
    </div>
  )
}

export default WebGL
