import React, { memo, useEffect, useState } from 'react'
import classnames from 'classnames'

import styles from './ScrollablePortfolio.module.scss'
import PortfolioScrollText from '../PortfolioScrollText/PortfolioScrollText'

import PortfolioSingleContent from '../PortfolioSingleContent/PortfolioSingleContent'
import useStore from 'store'

function ScrollablePortfolio({ className }) {
  const portfolioItems = useStore(state => state.portfolioItems)

  if (!portfolioItems?.length) return null

  return (
    <div className={classnames(styles.ScrollablePortfolio, className)}>
      <PortfolioScrollText
        className={styles.portfolioItem}
        items={portfolioItems}
      />
      <PortfolioSingleContent portfolioItems={portfolioItems} />
    </div>
  )
}

export default memo(ScrollablePortfolio)
