import React, { memo } from 'react'
import classnames from 'classnames'
import styles from './PortfolioScrollImages.module.scss'
import useBreakpoint from 'utils/hooks/use-breakpoint'
import { getImageUrl as getResizedImage } from 'components/SanityImage/SanityImage'
import useStore from 'store'

export const MAIN_IMAGE_CLASS_NAME = styles.image
export const CONTAINER_CLASS_NAME = styles.PortfolioScrollImages

function PortfolioScrollImages({ className }) {
  const { isMobile } = useBreakpoint()
  const portfolioItems = useStore(state => state.portfolioItems)

  if (!portfolioItems.length) return null

  return (
    <ul className={classnames(CONTAINER_CLASS_NAME, className, { [styles.isEven]: portfolioItems.length % 2 === 0 })}>
      {portfolioItems.map((item, i) => {
        const imageUrl = getImage(item.image)

        return (
          <li
            className={styles.item}
            key={i}
          >
            <img
              className={MAIN_IMAGE_CLASS_NAME}
              data-src={imageUrl}
              data-is-password-protected={item.isPasswordProtected}
            />
            <span className={styles.brand}>{item.brand}</span>
          </li>
        )
      })}
    </ul>
  )
}

function getImage(image) {
  const windowWidth = window.innerWidth

  let imageSrc = getResizedImage(image, { width: 650 })

  if (windowWidth > 2000) {
    imageSrc = getResizedImage(image, { width: 1500 })
  } else if (windowWidth > 768) {
    imageSrc = getResizedImage(image, { width: Math.round(windowWidth * 0.75) })
  }

  return imageSrc
}

export default memo(PortfolioScrollImages)
