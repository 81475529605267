import { useEffect, useState } from 'react'

export default function useNewKeyOnWindowResize() {
  const [previousWidth, setPreviousWidth] = useState()
  const [previousHeight, setPreviousHeight] = useState()
  const [key, setKey] = useState(0)

  useEffect(() => {
    if (previousWidth === null) {
      setPreviousWidth(window.innerWidth)
      return
    }

    if (previousHeight === null) {
      setPreviousHeight(window.innerHeight)
      return
    }

    let resizeTimeout = null

    function handleResize() {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout)
      }

      resizeTimeout = setTimeout(() => {
        if (window.innerWidth !== previousWidth) {
          setPreviousWidth(window.innerWidth)
          setKey(Date.now())
        }
        if (window.innerHeight !== previousHeight) {
          setPreviousHeight(window.innerHeight)
          setKey(Date.now())
        }
      }, 500)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [previousWidth, previousHeight])

  return key
}
