import asset from "./asset";

export const fields = `
	_type,
  description,
  keywords,
  robots,
  twitterHandle,
	${asset("shareAsset")}
`;

export const fragment = (name = "metaData") => `${name}{ ${fields} }`;

export default fragment;
