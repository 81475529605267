import asset, { fields as assetFields } from './asset'

export const fields = `
	_type,
  bio,
  images[] {
    ${asset('image')},
    nathanIsFocus
  }
`

export const fragment = (name = 'aboutContent') => `${name}{ ${fields} }`

export default fragment
