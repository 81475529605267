import { useEffect, useState } from 'react'
import Item, { IMAGE_GALLERY_TYPES } from '../PortfolioImage'
import { MAIN_IMAGE_CLASS_NAME } from 'components/PortfolioItems/PortfolioScrollImages/PortfolioScrollImages'
import useStore from 'store'
import { Suspense } from 'react'
import useTransitionState from 'utils/hooks/use-transition-state'
import { PAGE_TRANSITION_DURATION_MS } from 'data'

export default function PortfolioItems() {
  const [mainPortfolioDomElements, setMainPortfolioDomElements] = useState([])
  const portfolioItems = useStore(state => state.portfolioItems)
  const portfolioState = useStore(state => state.portfolioState)
  const [isVisible, setIsVisible] = useState(true)
  const { isPortfolio } = useTransitionState()

  useEffect(() => {
    if (isPortfolio) {
      const isActive =
        portfolioState === 'TRANSITIONING_TO_SINGLE' ||
        portfolioState === 'MAIN' ||
        portfolioState === 'TRANSITIONING_TO_MAIN'
      setIsVisible(isActive)
    } else {
      setTimeout(() => {
        setIsVisible(false)
      }, PAGE_TRANSITION_DURATION_MS)
    }
  }, [portfolioState, isPortfolio])

  useEffect(() => {
    if (!portfolioItems.length) return

    const imageElements = document.querySelectorAll(`.${MAIN_IMAGE_CLASS_NAME}`)
    if (imageElements.length) setMainPortfolioDomElements([...imageElements])
  }, [portfolioItems])

  if (!portfolioItems.length || !mainPortfolioDomElements.length) return null

  return (
    <>
      {mainPortfolioDomElements.map((element, i) => {
        return (
          <Suspense key={`main_${i}`}>
            <Item
              visible={isVisible}
              element={element}
              isPasswordProtected={element.dataset.isPasswordProtected === 'true'}
              index={i}
              itemsLength={portfolioItems.length}
              type={IMAGE_GALLERY_TYPES.MAIN}
              assetType="image"
            />
          </Suspense>
        )
      })}
    </>
  )
}
