export const fields = `
  _key,
  _type,
  label,
  linkType,
  linkType == "Internal" => {
    'link': internalLink -> {
      _id,
      _type,
      'slug': slug.current
    }
  },
  linkType == "External" => { 'link': externalLink }
`;

export const fragment = (linkEl = "link") => `${linkEl}{${fields}}`;

export default fragment;
