import { Uniform } from 'three'
import { Effect } from 'postprocessing'

import shader from './shader'
import { forwardRef, useImperativeHandle, useMemo } from 'react'

let _uStrength

class HorizontalDistortionEffect extends Effect {
  constructor({ strength = 0 } = {}) {
    super('HorizontalDistortionEffect', shader.fragmentShader, {
      uniforms: new Map([['strength', new Uniform(strength)]]),
    })

    _uStrength = strength
  }

  /**
   * Updates this effect.
   *
   * @param {WebGLRenderer} renderer - The renderer.
   * @param {WebGLRenderTarget} inputBuffer - A frame buffer that contains the result of the previous pass.
   * @param {Number} [deltaTime] - The time between the last frame and the current one in seconds.
   */

  update(renderer, inputBuffer, deltaTime) {
    // this.uniforms.get('strength').value = _uStrength
  }
}

const HorizontalDistortion = forwardRef(({ strength = 0 }, ref) => {
  const effect = useMemo(() => {
    return new HorizontalDistortionEffect({ strength })
  }, [strength])

  return (
    <primitive
      ref={ref}
      object={effect}
      dispose={null}
    />
  )
})

export default HorizontalDistortion
