import asset, { fields as assetFields } from './asset'

export const fields = `
	_type,
  isPasswordProtected,
  brand,
  title,
  year,
  role,
  agency,
  teammateComments,
  awards,
  link,
  ${asset('image')},
  images[] {
    itemType,
    videoURL,
    videoURLMobile,
    ${asset('image')},
    videoDimensions
  }
`

export const fragment = (name = 'portfolioItem') => `${name}{ ${fields} }`

export default fragment
