import React, { memo, useEffect, useState } from 'react'
import classnames from 'classnames'
import styles from './AboutImages.module.scss'
import { useRef } from 'react'
import useStore from 'store'
import gsap from 'gsap'
import SplitText, { letterClass } from 'utils/splitText'
import useTransitionState from 'utils/hooks/use-transition-state'
import { shuffle, lerp, simpleImagesPreload } from 'utils'
import SanityImage from 'components/SanityImage/SanityImage'
import { CURSOR_TYPES } from 'components/Cursor/Cursor'
import useBreakpoint from 'utils/hooks/use-breakpoint'
import useNewKeyOnWindowResize from 'utils/hooks/use-new-key-on-window-resize'
import { getIsTouchDevice } from 'utils/detect'

const getCurrentDragCoord = e => {
  const xCoord = e.changedTouches?.length ? e.changedTouches[0]?.clientX : e.clientX
  const yCoord = e.changedTouches?.length ? e.changedTouches[0]?.clientY : e.clientY

  return {
    x: xCoord,
    y: yCoord,
  }
}

const getYOffsetValue = yCoord => {
  return yCoord + window.pageYOffset
}

const PASSABLE_DRAG_DISTANCE = {
  desktop: 200,
  mobile: 75,
}
const LERP_LEVEL = 0.1
const IMAGE_COUNT = 4

function AboutImages({ className, images }) {
  const portfolioWebglImageLoaded = useStore(state => state.portfolioWebglImageLoaded)
  const aboutImages = useStore(state => state.aboutImages)
  const setAboutImages = useStore(state => state.setAboutImages)
  const setCursorType = useStore(state => state.setCursorType)
  const elements = useRef({
    largeText: null,
  })
  const [textIsSplit, setTextIsSplit] = useState(false)
  const { isAbout } = useTransitionState()
  const [imageIndexesShown, setImageIndexesShown] = useState(IMAGE_COUNT)
  const [imageWidthVariance, setImageWidthVariance] = useState([])
  const [imagesHaveAnimatedIn, setImagesHaveAnimatedIn] = useState(false)
  const { isMobile } = useBreakpoint()
  const [isPastThreshold, setIsPastThreshold] = useState(false)
  const [indexHovering, setIndexHovering] = useState(null)
  const key = useNewKeyOnWindowResize()

  // Element refs
  const imageContainerRefs = useRef([])
  const imageRefs = useRef([])
  const captionBgRefs = useRef([])
  const captionTextRefs = useRef([])

  // Index stuff
  const [activeIndex, setActiveIndex] = useState(0)
  const [indexToAnimateOut, setIndexToAnimateOut] = useState(null)
  const activeIndexRef = useRef(0)

  // Drag functionality
  const imageCoordinates = useRef({})
  const targetCoordinates = useRef({})
  const tempCoordinates = useRef({})
  const isDragging = useRef(false)
  const mouseDownXCoord = useRef(0)
  const dragDeltaTarget = useRef({ x: 0, y: 0, rotate: 0 })
  const mouseDownYCoord = useRef(0)
  const rafRef = useRef()

  const raf = () => {
    rafRef.current = requestAnimationFrame(raf)

    const element = imageContainerRefs.current[activeIndexRef.current]
    if (!element) return

    /*
    dragDeltaCurrent.current = {
      x: lerp(dragDeltaCurrent.current.x, dragDeltaTarget.current.x, LERP_LEVEL),
      y: lerp(dragDeltaCurrent.current.y, dragDeltaTarget.current.y, LERP_LEVEL),
      rotate: lerp(dragDeltaCurrent.current.rotate, dragDeltaTarget.current.rotate, LERP_LEVEL),
    }
    */

    if (!imageCoordinates.current[activeIndexRef.current]) return

    imageCoordinates.current[activeIndexRef.current] = {
      x: lerp(
        imageCoordinates.current[activeIndexRef.current].x,
        targetCoordinates.current[activeIndexRef.current].x,
        LERP_LEVEL,
      ),
      y: lerp(
        imageCoordinates.current[activeIndexRef.current].y,
        targetCoordinates.current[activeIndexRef.current].y,
        LERP_LEVEL,
      ),
      rotate: lerp(
        imageCoordinates.current[activeIndexRef.current].rotate,
        targetCoordinates.current[activeIndexRef.current].rotate,
        LERP_LEVEL,
      ),
    }

    gsap.set(element, {
      x: imageCoordinates.current[activeIndexRef.current].x,
      y: imageCoordinates.current[activeIndexRef.current].y,
      rotate: imageCoordinates.current[activeIndexRef.current].rotate,
    })
  }

  const setElementCoords = (element, i) => {
    const varianceLower = isMobile ? -6 : -10
    const varianceUpper = isMobile ? 6 : 10
    const xVariance = gsap.utils.random(varianceLower, varianceUpper)
    const yVariance = gsap.utils.random(varianceLower, varianceUpper)
    const x = window.innerWidth * 0.5 - element.offsetWidth * 0.5 + xVariance
    const y = window.innerHeight * 0.5 - element.offsetHeight * 0.5 + yVariance

    const value = {
      x,
      y,
      rotate: gsap.utils.random(varianceLower, varianceUpper),
    }

    imageCoordinates.current[i] = value
    targetCoordinates.current[i] = value
    tempCoordinates.current[i] = value
  }

  useEffect(() => {
    imageContainerRefs.current.forEach((el, i) => {
      setElementCoords(el, i)
      gsap.set(el, {
        ...imageCoordinates.current[i],
      })
    })
  }, [key])

  // set ref of active index
  useEffect(() => {
    activeIndexRef.current = activeIndex
  }, [activeIndex])

  // Animate image if dragged past threshold
  useEffect(() => {
    if (!imageRefs.current[activeIndex]) return

    gsap.killTweensOf(imageRefs.current[activeIndex])
    gsap.to(imageRefs.current[activeIndex], {
      autoAlpha: isPastThreshold ? 0.2 : 1,
    })
  }, [isPastThreshold, activeIndex])

  // Animate index on hovers
  useEffect(() => {
    const ease = 'Power4.easeOut'
    const duration = 0.8

    captionBgRefs.current.forEach((el, i) => {
      gsap.killTweensOf(el)

      gsap.to(el, {
        scaleX: i === indexHovering ? 1 : 0,
        ease,
        duration,
      })
    })

    captionTextRefs.current.forEach((el, i) => {
      gsap.killTweensOf(el)

      gsap.to(el, {
        y: i === indexHovering ? 0 : '105%',
        ease,
        duration,
      })
    })
  }, [indexHovering])

  // Update positions of images upon new indexes shown
  useEffect(() => {
    if (!imagesHaveAnimatedIn) return

    setTimeout(() => {
      const newIndex = imageContainerRefs.current.length - 1

      if (newIndex > images.length) return

      const newElement = imageContainerRefs.current[newIndex]

      if (!newElement) return

      // Set new element coordinates
      if (!imageCoordinates.current[newIndex]) {
        setElementCoords(newElement, newIndex)
      }

      // position all items
      imageContainerRefs.current.forEach((el, i) => {
        if (i === newIndex) return
        const props = imageCoordinates.current[i]
        gsap.set(el, {
          ...props,
        })
      })

      // Preload image, when complete,
      // set and show new image
      const imageTag = newElement.querySelectorAll('img')[0]
      const src = imageTag.currentSrc

      function setAndShowImage() {
        const props = imageCoordinates.current[newIndex]
        gsap.set(newElement, {
          ...props,
        })
        gsap.to(newElement, {
          autoAlpha: 1,
        })
      }

      if (src) {
        simpleImagesPreload({
          urls: [src],
          onComplete: () => {
            setAndShowImage()
          },
        })
      } else {
        setAndShowImage()
      }
    }, 15)
  }, [imageIndexesShown, images.length])

  // Animate image out
  useEffect(() => {
    if (indexToAnimateOut === null || !imageContainerRefs.current[indexToAnimateOut]) return

    gsap.killTweensOf(imageContainerRefs.current[indexToAnimateOut])
    gsap.to(imageContainerRefs.current[indexToAnimateOut], {
      autoAlpha: 0,
      duration: 0.2,
      display: 'none',
      onComplete: () => {
        setActiveIndex(indexToAnimateOut + 1)
        setImageIndexesShown(prev => prev + 1)
      },
    })
  }, [indexToAnimateOut])

  useEffect(() => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current)
      rafRef.current = null
    }

    if (!imagesHaveAnimatedIn || !portfolioWebglImageLoaded) return

    raf()
  }, [portfolioWebglImageLoaded, imagesHaveAnimatedIn])

  useEffect(() => {
    return () => {
      cancelAnimationFrame(rafRef.current)
    }
  }, [])

  useEffect(() => {
    const element = imageContainerRefs.current[activeIndex]
    const distanceThresh = isMobile ? PASSABLE_DRAG_DISTANCE.mobile : PASSABLE_DRAG_DISTANCE.desktop

    if (!element) return

    const handeOnMouseDown = e => {
      isDragging.current = true
      const { x, y } = getCurrentDragCoord(e)
      mouseDownXCoord.current = x
      mouseDownYCoord.current = getYOffsetValue(y)
      document.body.dataset.cursor = 'grabbing'
    }

    const handleDrag = e => {
      if (!isDragging.current) return
      if (!mouseDownXCoord.current) return

      const { x, y } = getCurrentDragCoord(e)

      let deltaX = 0
      if (x !== 0) {
        deltaX = x >= mouseDownXCoord.current ? x - mouseDownXCoord.current : (mouseDownXCoord.current - x) * -1
      }

      const rotate = deltaX * 0.15

      let deltaY = 0
      if (y !== 0) {
        deltaY = y >= mouseDownYCoord.current ? y - mouseDownYCoord.current : (mouseDownYCoord.current - y) * -1
      }

      dragDeltaTarget.current.x = deltaX
      dragDeltaTarget.current.y = deltaY
      dragDeltaTarget.current.rotate = rotate

      setIsPastThreshold(Math.abs(dragDeltaTarget.current.x) > distanceThresh)

      targetCoordinates.current[activeIndex] = {
        x: tempCoordinates.current[activeIndex].x + deltaX,
        y: tempCoordinates.current[activeIndex].y + deltaY,
        rotate: tempCoordinates.current[activeIndex].rotate + rotate,
      }
    }

    const handleDragLeave = () => {
      isDragging.current = false
      document.body.dataset.cursor = 'grab'

      if (Math.abs(dragDeltaTarget.current.x) > distanceThresh) {
        tempCoordinates.current[activeIndex] = targetCoordinates.current[activeIndex]
        setIndexToAnimateOut(activeIndex)
        setIsPastThreshold(false)
      } else {
        targetCoordinates.current[activeIndex] = tempCoordinates.current[activeIndex]
      }

      dragDeltaTarget.current.x = 0
      dragDeltaTarget.current.y = 0
      dragDeltaTarget.current.rotate = 0
    }

    if (getIsTouchDevice()) {
      element.removeEventListener('touchmove', handleDrag)
      element.removeEventListener('touchend', handleDragLeave)
      element.removeEventListener('touchcancel', handleDragLeave)
      element.removeEventListener('touchstart', handeOnMouseDown)
    } else {
      element.removeEventListener('pointermove', handleDrag)
      element.removeEventListener('pointerdown', handeOnMouseDown)
      element.removeEventListener('pointerup', handleDragLeave)
      element.removeEventListener('pointercancel', handleDragLeave)
    }

    if (getIsTouchDevice()) {
      element.addEventListener('touchmove', handleDrag)
      element.addEventListener('touchend', handleDragLeave)
      element.addEventListener('touchcancel', handleDragLeave)
      element.addEventListener('touchstart', handeOnMouseDown)
    } else {
      element.addEventListener('pointermove', handleDrag)
      element.addEventListener('pointerdown', handeOnMouseDown)
      element.addEventListener('pointerup', handleDragLeave)
      element.addEventListener('pointercancel', handleDragLeave)
    }

    return () => {
      if (getIsTouchDevice()) {
        element.removeEventListener('touchmove', handleDrag)
        element.removeEventListener('touchend', handleDragLeave)
        element.removeEventListener('touchcancel', handleDragLeave)
        element.removeEventListener('touchstart', handeOnMouseDown)
      } else {
        element.removeEventListener('pointermove', handleDrag)
        element.removeEventListener('pointerdown', handeOnMouseDown)
        element.removeEventListener('pointerup', handleDragLeave)
        element.removeEventListener('pointercancel', handleDragLeave)
      }
    }
  }, [activeIndex, portfolioWebglImageLoaded, imagesHaveAnimatedIn, isMobile])

  useEffect(() => {
    if (!images?.length || aboutImages.length) return
    const newImages = shuffle(images)

    const imagesOfMe = newImages.filter(item => item.nathanIsFocus)
    const imagesToSet = newImages.filter(item => !item.nathanIsFocus)
    const imagesInBetween = Math.floor(imagesToSet.length / imagesOfMe.length)
    for (let index = 0; index < imagesOfMe.length; index++) {
      const imageElement = imagesOfMe[index]
      imagesToSet.splice(index * imagesInBetween + index, 0, imageElement)
    }

    setAboutImages(imagesToSet)
  }, [images, setAboutImages, aboutImages])

  useEffect(() => {
    if (imagesHaveAnimatedIn) return
    if (!portfolioWebglImageLoaded || !isAbout) return
    if (!aboutImages.length || !imageContainerRefs.current.length) return

    const percent = (100 / (IMAGE_COUNT + 1)) * 0.01
    const distanceToFuckingFly = window.innerWidth

    setTimeout(() => {
      imageContainerRefs.current.forEach((el, i) => {
        setElementCoords(el, i)
      })

      gsap.fromTo(
        imageContainerRefs.current,
        {
          rotate: () => {
            return gsap.utils.random(-90, 90)
          },
          x: i => {
            return i * percent * distanceToFuckingFly
          },
          y: window.innerHeight * 1.2,
          autoAlpha: 1,
        },
        {
          autoAlpha: 1,
          rotate: i => {
            return imageCoordinates.current[i].rotate
          },
          x: i => {
            return imageCoordinates.current[i].x
          },
          y: i => {
            return imageCoordinates.current[i].y
          },
          ease: 'Power4.easeOut',
          duration: 1.2,
          stagger: 0.2,
          onComplete: () => {
            setImagesHaveAnimatedIn(true)
          },
        },
      )
    }, 50)
  }, [aboutImages, portfolioWebglImageLoaded, isAbout])

  useEffect(() => {
    if (!imageContainerRefs.current.length) return
    if (imageWidthVariance.length) return

    const varianceArray = []
    aboutImages.forEach((_, i) => {
      // Width variance
      const value = isMobile ? gsap.utils.random(0.95, 1.05, 0.01) : gsap.utils.random(0.85, 1.15, 0.01)
      varianceArray.push(value)
    })

    setImageWidthVariance(varianceArray)
  }, [aboutImages, imageWidthVariance, setImageWidthVariance, isMobile])

  useEffect(() => {
    if (!elements.current.largeText) return
    SplitText.init(elements.current.largeText, {
      spacing: 0.3,
    })
    setTimeout(() => {
      setTextIsSplit(true)
    }, 20)
  }, [])

  useEffect(() => {
    if (!portfolioWebglImageLoaded || !isAbout) return

    const ease = 'Power4.easeOut'

    if (elements.current.largeText) {
      const letters = elements.current.largeText.querySelectorAll(`.${letterClass}`)

      gsap.to([...letters], {
        y: 0,
        ease,
        stagger: 0.05,
        duration: 1.4,
        delay: 0.5,
      })
    }
  }, [portfolioWebglImageLoaded, isAbout])

  if (!images?.length) return null

  return (
    <>
      <div className={classnames(styles.AboutImages, className, { [styles.textIsSplit]: textIsSplit })}>
        <div className={styles.largeTextContainer}>
          <span
            className={styles.largeTextContainer__text}
            ref={ref => {
              elements.current.largeText = ref
            }}
          >
            That's It.
          </span>
        </div>
      </div>
      <ul className={styles.imageList}>
        {aboutImages.map((item, i) => {
          if (i > imageIndexesShown) return null

          const aspect = item?.image?.asset?.metadata?.dimensions?.aspectRatio

          return (
            <li
              className={classnames(
                styles.imageContainer,
                { [styles.portrait]: aspect < 1 },
                { [styles.skinny]: aspect < 0.7 },
                { [styles.wide]: aspect > 1.5 },
                { [styles.square]: aspect < 1.1 && aspect > 0.9 },
                { [styles.active]: activeIndex === i },
              )}
              onMouseEnter={() => {
                setCursorType(CURSOR_TYPES.GRAB)
                setIndexHovering(i)
              }}
              onMouseLeave={() => {
                setCursorType(null)
                setIndexHovering(null)
              }}
              key={i}
              style={{ zIndex: aboutImages.length - i + 3, '--width-variance': imageWidthVariance[i] }}
              ref={ref => {
                imageContainerRefs.current[i] = ref
              }}
            >
              <div
                ref={ref => {
                  imageRefs.current[i] = ref
                }}
              >
                <SanityImage
                  className={styles.image}
                  image={item.image}
                  unoptimized={true}
                  breakpoints={[
                    {
                      breakpoint: 1440,
                      image: item.image,
                      width: 1200,
                    },
                    {
                      breakpoint: 768,
                      image: item.image,
                      width: 900,
                    },
                    {
                      breakpoint: 0,
                      image: item.image,
                      width: 300,
                    },
                  ]}
                />
              </div>
              <div className={styles.altContainer}>
                <div
                  className={styles.altContainer__bg}
                  ref={ref => {
                    captionBgRefs.current[i] = ref
                  }}
                />
                <span
                  className={styles.altContainer__text}
                  ref={ref => {
                    captionTextRefs.current[i] = ref
                  }}
                >
                  {item.image.alt}
                </span>
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default memo(AboutImages)
