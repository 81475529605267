import { createClient } from 'next-sanity'
import { metaData, loader, modules } from './fragments'

export const client = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION,
  useCdn: process.env.NEXT_PUBLIC_SANITY_USE_CDN === 'true' ? true : false,
  // perspective: 'previewDrafts',
  // useCdn: true,
  // useCdn: true,
})

const settingsFields = `
    _type,
    siteTitle,
    availability,
    ${metaData.fragment()},
    ${loader.fragment()}
`

const getSiteSettingsPromise = () => {
  return client.fetch(`
    *[_type == "settings"] {
        ${settingsFields}
    }[0]
   `)
}

export const getPageStaticPaths = async () => {
  const response = await client.fetch(`*[_type == "page"]`)

  const pathsData = response
    .map(page => {
      if (page.slug.current === '404') return

      return {
        params: { slug: page.slug.current },
      }
    })
    .filter(n => n)

  return pathsData
}

export const getSiteSettings = async () => {
  const siteSettings = await getSiteSettingsPromise()
  return siteSettings
}

export const getPage = async slug => {
  let allContent = await client.fetch(`
      *[_type == "settings" || _type == "page"] {
            _type == "settings" => { 
                ${settingsFields}
            },
            _type == "page" => { 
                _type,
                title,
                slug,
                theme,
                ${metaData.fragment()},
                ${modules.fragment()}
            },
        }
    `)

  const siteSettings = allContent.filter(obj => obj._type === 'settings')[0]
  const allPageData = allContent.filter(obj => obj._type === 'page')
  const pageData = allPageData.filter(page => page.slug.current === slug)[0]

  if (!pageData) return

  if (pageData.metaData) {
    Object.keys(pageData.metaData).forEach(key => {
      if (key === 'robots') return

      if (!pageData.metaData[key]) {
        delete pageData.metaData[key]
      }
    })
  }

  const data = {
    metaData: {
      ...siteSettings.metaData,
      ...pageData.metaData,
      title: `${siteSettings.siteTitle} ${pageData.title !== 'Home' ? `| ${pageData.title}` : ''}`,
    },
    allPages: allPageData,
    siteSettings,
  }

  return data
}

export default client
