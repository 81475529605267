import { useEffect, useRef, useState } from 'react'
import styles from './BlockedContentModal.module.scss'
import useStore from 'store'
import classNames from 'classnames'

const LOCAL_STORAGE_KEY = 'passverd'

export const hasValidPassword = () => {
  const localPassword = localStorage.getItem(LOCAL_STORAGE_KEY)
  return localPassword === process.env.NEXT_PUBLIC_BLOCKED_CONTENT_PASSWORD
}

const BlockedContentModal = () => {
  const passwordPromptOpen = useStore(state => state.passwordPromptOpen)
  const setPasswordPromptOpen = useStore(state => state.setPasswordPromptOpen)
  const setActivePortfolioItemIndex = useStore(state => state.setActivePortfolioItemIndex)
  const passwordPromptGoToIndex = useStore(state => state.passwordPromptGoToIndex)
  const setPortfolioState = useStore(state => state.setPortfolioState)
  const [inputValue, setInputValue] = useState('')
  const [isError, setIsError] = useState(false)
  const inputRef = useRef()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!passwordPromptOpen) return
    inputRef.current.focus()
  }, [passwordPromptOpen])

  const handleSubmit = e => {
    e.preventDefault()

    if (inputValue === process.env.NEXT_PUBLIC_BLOCKED_CONTENT_PASSWORD) {
      document.body.focus()
      setLoading(true)

      setTimeout(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, inputValue)
        setPasswordPromptOpen(false)
        setActivePortfolioItemIndex(passwordPromptGoToIndex)
        setPortfolioState('TRANSITIONING_TO_SINGLE')
      }, 1000)
    } else {
      setIsError(true)
    }
  }

  if (!passwordPromptOpen) return null

  return (
    <div className={classNames(styles.BlockedContentModal, { [styles.isError]: isError })}>
      <div
        className={styles.bg}
        onClick={() => {
          setPasswordPromptOpen(false)
        }}
      />
      <div className={styles.inner}>
        <p className={styles.inner__text}>
          This content is password-blocked.
          <br />
          Please enter the magic word to continue.
        </p>
        <form
          onSubmit={handleSubmit}
          className={styles.form}
          autocomplete="off"
        >
          <input
            className={styles.input}
            type="text"
            autoComplete="false"
            onChange={e => {
              setIsError(false)
              setInputValue(e.target.value)
            }}
            ref={inputRef}
          />
          <button
            type="submit"
            className={styles.button}
          >
            {loading ? 'LOADING' : 'SUBMIT'}
          </button>
        </form>
      </div>
    </div>
  )
}

BlockedContentModal.displayName = 'BlockedContentModal'

export default BlockedContentModal
