import React, { memo, useEffect } from 'react'
import classnames from 'classnames'
import styles from './PortfolioItems.module.scss'
import useStore from 'store'
import ScrollablePortfolio from 'components/PortfolioItems/ScrollablePortfolio/ScrollablePortfolio'
import { PAGE_TRANSITION_DURATION_MS } from 'data'
import { useRef } from 'react'
import useTransitionState from 'utils/hooks/use-transition-state'
import gsap from 'gsap'
import { useState } from 'react'
import { getSortedGalleryItems } from 'utils'
import Background from 'components/Background/Background'

function PortfolioItems({ portfolioItems, className }) {
  const setPortfolioItems = useStore(state => state.setPortfolioItems)
  const containerRef = useRef()
  const { isPortfolio } = useTransitionState()
  const setPortfolioScrollDistance = useStore(state => state.setPortfolioScrollDistance)
  const setPortfolioScrollDelta = useStore(state => state.setPortfolioScrollDelta)
  const setPortfolioScrollDirection = useStore(state => state.setPortfolioScrollDirection)
  const setActivePortfolioItemIndex = useStore(state => state.setActivePortfolioItemIndex)
  const setPortfolioState = useStore(state => state.setPortfolioState)
  const portfolioWebglImageLoaded = useStore(state => state.portfolioWebglImageLoaded)
  const [isHidden, setIsHidden] = useState(false)
  const transitionTimeoutRef = useRef(null)
  const pageIsActive = portfolioWebglImageLoaded && isPortfolio

  useEffect(() => {
    if (portfolioItems) {
      setPortfolioItems(getSortedGalleryItems(portfolioItems))
    }
  }, [setPortfolioItems, portfolioItems])

  useEffect(() => {
    if (!portfolioWebglImageLoaded) return

    if (transitionTimeoutRef.current) {
      clearTimeout(transitionTimeoutRef.current)
    }

    if (isPortfolio) {
      setIsHidden(false)
    } else {
      transitionTimeoutRef.current = setTimeout(() => {
        setPortfolioScrollDistance(0)
        setPortfolioScrollDelta(0)
        setPortfolioScrollDirection('up')
        setActivePortfolioItemIndex(null)
        setPortfolioState('MAIN')
        setIsHidden(true)
      }, PAGE_TRANSITION_DURATION_MS)
    }
  }, [isPortfolio, portfolioWebglImageLoaded])

  if (isHidden) return null

  return (
    <>
      <div
        ref={containerRef}
        className={classnames(styles.PortfolioItems, className, { [styles.active]: pageIsActive })}
      >
        <ScrollablePortfolio />
      </div>
      <Background
        className={classnames(styles.background, { [styles.bgActive]: pageIsActive })}
        theme="dark"
        active={pageIsActive}
      />
    </>
  )
}

export default memo(PortfolioItems)
