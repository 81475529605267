export const lerp = (currentValue, targetValue, ease) => currentValue + (targetValue - currentValue) * ease

export const simpleImagesPreload = ({ urls, onComplete, onProgress }) => {
  let loadedCounter = 0
  const toBeLoadedNumber = urls.length

  urls.forEach(function (url) {
    preloadImage(url, function () {
      loadedCounter++
      if (onProgress) {
        onProgress({
          percent: loadedCounter / toBeLoadedNumber,
          toLoad: toBeLoadedNumber,
          loaded: loadedCounter,
        })
      }
      if (loadedCounter === toBeLoadedNumber) {
        if (onComplete) onComplete()
      }
    })
  })

  function preloadImage(url, anImageLoadedCallback) {
    const img = new Image()
    img.onload = anImageLoadedCallback
    img.src = url
  }
}

export const getSortedGalleryItems = items => {
  const sortedItems = []
  for (let index = 0; index < items.length; index++) {
    const appendItem = items[index]
    const prependItem = items[items.length - (index + 1)]

    if (sortedItems.length !== items.length) {
      sortedItems.push(appendItem)
    }
    if (sortedItems.length !== items.length) {
      sortedItems.unshift(prependItem)
    }
  }
  return sortedItems
}

export const shuffle = array => {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}
