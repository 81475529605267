export const getMobileLineAnimationProps = button => {
  const distance = button.offsetWidth * 1

  const lineHashMap = {
    0: {
      active: {
        scaleX: 4,
        x: distance,
      },
      inactive: {
        scaleX: 1,
        x: 0,
      },
    },
    1: {
      active: {
        scaleY: 4,
        y: -distance,
      },
      inactive: {
        scaleY: 1,
        y: 0,
      },
    },
    2: {
      active: {
        scaleX: 1,
        x: 0,
      },
      inactive: {
        scaleX: 4,
        x: -distance * 1.4,
      },
    },
  }

  return lineHashMap
}
