import React, { memo, useEffect } from 'react'
import classnames from 'classnames'
import styles from './AboutRotator.module.scss'
import { useRef } from 'react'
import gsap from 'gsap'
import { useState } from 'react'
import useNewKeyOnWindowResize from 'utils/hooks/use-new-key-on-window-resize'
import { shuffle } from 'utils'

const POINTS = [
  {
    text: 'Top Gun 2',
    icon: '🤘',
  },
  {
    text: "Arby's",
    icon: '🤌',
  },
  {
    text: 'John Wick 4',
    icon: '🤘',
  },
  {
    text: 'Tank Tops',
    icon: '🤘',
  },
  {
    text: 'A Meaty Bolognese',
    icon: '🤌',
  },
  {
    text: 'Kenny Powers',
    icon: '🔥',
  },
  {
    text: "Guns N' Roses",
    icon: '🤘',
  },
  {
    text: 'Spaceship Superstar by Prism',
    icon: '🤘',
  },
]

const DELAY = 3000

const AboutRotator = React.forwardRef(({ className }, ref) => {
  const refs = useRef([])
  const intervalRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const key = useNewKeyOnWindowResize()
  const [points, setPoints] = useState([])

  useEffect(() => {
    setPoints(shuffle(POINTS))
  }, [])

  useEffect(() => {
    if (!points) return

    if (intervalRef.current) {
      setActiveIndex(0)
      clearInterval(intervalRef.current)
      intervalRef.current = null

      gsap.set(refs.current, {
        clearProps: 'all',
      })
    }

    let inc = 0
    intervalRef.current = setInterval(() => {
      if (inc === points.length - 1) {
        inc = 0
      } else {
        inc = inc + 1
      }
      setActiveIndex(inc)
    }, DELAY)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [key, points])

  useEffect(() => {
    if (!points) return

    let prevIndex = activeIndex - 1
    if (prevIndex === -1) {
      prevIndex = refs.current.length - 1
    }
    refs.current.forEach((el, i) => {
      if (i === activeIndex) {
        gsap.fromTo(
          el,
          {
            y: '105%',
          },
          {
            y: 0,
          },
        )
      } else if (i === prevIndex) {
        gsap.fromTo(
          el,
          {
            y: 0,
          },
          {
            y: '-105%',
          },
        )
      } else {
        gsap.set(el, {
          y: '105%',
        })
      }
    })
  }, [activeIndex, points])

  return (
    <ul
      className={classnames(styles.rotator, className)}
      ref={ref}
    >
      {points.map((point, i) => {
        if (!point.text || !point.icon) return

        return (
          <li
            className={styles.rotatorContainer__item}
            key={i}
            data-icon={point.text.toLowerCase()}
            ref={ref => {
              refs.current[i] = ref
            }}
          >
            {point.text} = {point.icon}
          </li>
        )
      })}
    </ul>
  )
})

export default memo(AboutRotator)
