import { useCallback, useRef } from 'react'
import wait from '@jam3/wait'
import { simpleImagesPreload } from 'utils'
import styles from './use-load-assets.module.scss'
import { useState } from 'react'

export default function useLoadAssets() {
  const domContainerElement = useRef(null)
  const [totalAssetsToLoad, setTotalAssetsToLoad] = useState(0)

  const cleanup = () => {
    if (domContainerElement.current) {
      domContainerElement.current.remove()
    }
  }

  const getSrcArrayToLoad = useCallback(async () => {
    cleanup()

    const images = [...document.querySelectorAll('img'), ...document.querySelectorAll('picture')]
    const srcArray = []
    const cloneArray = []

    domContainerElement.current = document.createElement('div')
    domContainerElement.current.classList.add(styles.loadableImageContainer)
    document.body.appendChild(domContainerElement.current)

    if (!domContainerElement.current) return

    for (let index = 0; index < images.length; index++) {
      const element = images[index]

      const isPicture = element.nodeName === 'PICTURE'
      const parentIsNotPicture = element.parentElement.nodeName !== 'PICTURE'

      if (isPicture || parentIsNotPicture) {
        const clone = element.cloneNode(true)
        cloneArray.push(clone)
        domContainerElement.current.appendChild(clone)
      }
    }

    await wait(100)

    for (let index = 0; index < cloneArray.length; index++) {
      const clone = cloneArray[index]
      let src = ''
      if (clone.nodeName === 'IMG') {
        if (clone.currentSrc) {
          src = clone.currentSrc
        }
      } else {
        const img = clone.querySelectorAll('img')[0]
        if (img.currentSrc) {
          src = img.currentSrc
        }
      }
      if (src && !srcArray.includes(src)) {
        srcArray.push(src)
      }
    }

    return srcArray
  }, [])

  const loadAssets = useCallback(
    async (callback, onProgressCallback) => {
      const srcArray = await getSrcArrayToLoad()
      setTotalAssetsToLoad(srcArray.length)

      if (!srcArray.length) return

      simpleImagesPreload({
        urls: srcArray,
        onProgress: progress => {
          if (onProgressCallback) {
            onProgressCallback(progress)
          }
        },
        onComplete: () => {
          if (callback) callback()
          cleanup()
        },
      })
    },
    [getSrcArrayToLoad],
  )

  return {
    loadAssets,
    getSrcArrayToLoad,
    totalAssetsToLoad,
  }
}
