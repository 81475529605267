import { useRouter } from 'next/router'

export default function useTransitionState() {
  const router = useRouter()
  const isPortfolio = router.asPath === '/' || router.asPath === ''
  const isAbout = router.asPath === '/about/'

  return {
    isPortfolio,
    isAbout,
  }
}
