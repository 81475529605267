import { useEffect, useState } from 'react'
import Item, { IMAGE_GALLERY_TYPES } from '../PortfolioImage'
import { SINGLE_IMAGE_CLASS_NAME } from 'components/PortfolioItems/PortfolioScrollImagesSingle/PortfolioScrollImagesSingle'
import useStore from 'store'
import { Suspense } from 'react'
import useTransitionState from 'utils/hooks/use-transition-state'
import { PAGE_TRANSITION_DURATION_MS } from 'data'

export default function PortfolioItems() {
  const [singlePortfolioDomElements, setSinglePortfolioDomElements] = useState([])
  const activePortfolioItemIndex = useStore(state => state.activePortfolioItemIndex)
  const portfolioState = useStore(state => state.portfolioState)
  const portfolioItems = useStore(state => state.portfolioItems)
  const [isVisible, setIsVisible] = useState(false)
  const { isPortfolio } = useTransitionState()

  useEffect(() => {
    if (!portfolioItems.length) return

    const imageElements = document.querySelectorAll(`.${SINGLE_IMAGE_CLASS_NAME}`)
    if (imageElements.length) setSinglePortfolioDomElements([...imageElements])
  }, [activePortfolioItemIndex, portfolioItems])

  useEffect(() => {}, [portfolioState])

  useEffect(() => {
    if (isPortfolio) {
      const isActive =
        portfolioState === 'TRANSITIONING_TO_SINGLE' ||
        portfolioState === 'TRANSITIONING_TO_MAIN' ||
        portfolioState === 'SINGLE'
      setIsVisible(isActive)
    } else {
      setTimeout(() => {
        setIsVisible(false)
      }, PAGE_TRANSITION_DURATION_MS)
    }
  }, [portfolioState, isPortfolio])

  if (!singlePortfolioDomElements.length || !portfolioItems.length) return null

  return (
    <>
      {singlePortfolioDomElements.map((element, i) => {
        const parentListContainer = element?.parentNode?.parentNode

        if (!parentListContainer) return null

        const isMain = element.dataset.isMain === 'true'
        const isActiveIndex = parseInt(parentListContainer.dataset.index) === activePortfolioItemIndex
        const assetType = element.dataset.type ? element.dataset.type : 'image'

        return (
          <Suspense key={`single_${i}`}>
            <Item
              visible={isVisible && isActiveIndex}
              element={element}
              index={Array.from(parentListContainer.children).indexOf(element.parentNode)}
              itemsLength={Array.from(parentListContainer.children).length}
              isMain={isMain}
              type={IMAGE_GALLERY_TYPES.SINGLE}
              assetType={assetType ? assetType : 'image'}
            />
          </Suspense>
        )

        {
          /* return (
          <Suspense key={`single_${i}`}>
            <Item
              visible={isVisible && isActiveIndex}
              element={element}
              index={Array.from(parentListContainer.children).indexOf(element.parentNode)}
              itemsLength={Array.from(parentListContainer.children).length}
              isMain={isMain}
              type={IMAGE_GALLERY_TYPES.SINGLE}
              assetType={assetType ? assetType : 'image'}
            />
          </Suspense>
        ) */
        }
      })}
    </>
  )
}
