import create from 'zustand'
import { persist, subscribeWithSelector } from 'zustand/middleware'
import breakpoints from '../data/breakpoints'
import detect from '../utils/detect'

export const getBreakpoint = width => {
  if (width > 0) {
    for (const breakpoint of breakpoints) {
      if (width <= breakpoint.width) {
        return breakpoint
      }
    }
  }

  return breakpoints[breakpoints.length - 1]
}

export const UIStore = create(
  subscribeWithSelector(
    persist(
      set => ({
        // Global page data
        headerData: {},
        setHeaderData: headerData => set({ headerData }),
        siteSettings: {},
        setSiteSettings: siteSettings => set({ siteSettings }),
        loaderData: {},
        setLoaderData: loaderData => set({ loaderData }),
        portfolioItems: [],
        setPortfolioItems: portfolioItems => set({ portfolioItems }),
        currentPageData: {},
        setCurrentPageData: currentPageData => set({ currentPageData }),

        passwordPromptOpen: false,
        setPasswordPromptOpen: passwordPromptOpen => set({ passwordPromptOpen }),
        passwordPromptGoToIndex: null,
        setPasswordPromptGoToIndex: passwordPromptGoToIndex => set({ passwordPromptGoToIndex }),

        // Interacting/Animating
        canInteract: false,
        setCanInteract: canInteract => set({ canInteract }),

        // Header
        mobileHeaderOpen: false,
        setMobileHeaderOpen: mobileHeaderOpen => set({ mobileHeaderOpen }),

        //Cursor
        cursorType: null,
        setCursorType: cursorType => set({ cursorType }),

        // Loader
        loaderAnimationComplete: false,
        setLoaderAnimationComplete: loaderAnimationComplete => set({ loaderAnimationComplete }),

        // About
        aboutImages: [],
        setAboutImages: aboutImages => set({ aboutImages }),

        //Homepage
        portfolioWebglImageLoaded: false,
        // portfolioWebglImageLoaded: true,
        setPortfolioWebglImageLoaded: portfolioWebglImageLoaded => set({ portfolioWebglImageLoaded }),
        // portfolioLoadPercent: 1,
        portfolioLoadPercent: 0, // The percent that the application is loaded for video + imagery
        setPortfolioLoadPercent: portfolioLoadPercent => set({ portfolioLoadPercent }),
        portfolioWebglVideosLoaded: [], // just push the sources of videos that are loaded and compare against
        setPortfolioWebglVideosLoaded: videoSrc => {
          if (UIStore.getState().portfolioWebglVideosLoaded.includes(videoSrc)) return

          return set({
            portfolioWebglVideosLoaded: [...UIStore.getState().portfolioWebglVideosLoaded, videoSrc],
          })
        },
        portfolioScrollDistance: 0,
        setPortfolioScrollDistance: portfolioScrollDistance => set({ portfolioScrollDistance }),
        portfolioScrollDelta: 0,
        setPortfolioScrollDelta: portfolioScrollDelta => set({ portfolioScrollDelta }),
        totalPortfolioVideoItems: null,
        setTotalPortfolioVideoItems: totalPortfolioVideoItems => set({ totalPortfolioVideoItems }),
        portfolioScrollDirection: 'up', // 'up' or 'down'
        setPortfolioScrollDirection: portfolioScrollDirection => set({ portfolioScrollDirection }),
        activePortfolioItemIndex: null, // 'up' or 'down'
        setActivePortfolioItemIndex: activePortfolioItemIndex => set({ activePortfolioItemIndex }),
        portfolioState: 'MAIN', // 'MAIN', 'SINGLE', 'TRANSITIONING_TO_SINGLE', 'TRANSITIONING_TO_MAIN'
        setPortfolioState: portfolioState => set({ portfolioState }),

        // Page transitions
        pageIsTransitioning: false,
        setPageIsTransitioning: pageIsTransitioning => set({ pageIsTransitioning }),
        prevPath: null,
        setPrevPath: prevPath => set({ prevPath }),

        // General
        theme: 'dark',
        setTheme: theme => set({ theme }),
        contentHeight: 0,
        setContentHeight: contentHeight => set({ contentHeight }),
        breakpoint: { name: 'desktop', width: 1440 },
        setBreakpoint: breakpoint => set({ breakpoint }),
        isStoreRehydrated: false,
        setIsStoreRehydrated: isStoreRehydrated => set({ isStoreRehydrated }),
        isScrollLocked: false,
        updateIsScrollLocked: isScrollLocked => set({ isScrollLocked }),
        resetScrollDirection: () => set({ scrollDirection: undefined }),
        headerHeight: 0,
        updateHeaderHeight: headerHeight => set({ headerHeight }),
        deviceInfo: {
          ...detect,
        },
      }),
      {
        name: 'site-state',
        partialize: state => ({ theme: state.theme }),
        onRehydrateStorage: () => state => {
          if (state && !state?.isLoaded) {
            setTimeout(() => {
              state.setIsStoreRehydrated(true) // Needs quick tick or else does not update...
            }, 5)
          }
        },
      },
    ),
  ),
)

export default UIStore
