import { memo, useEffect, useRef } from 'react'
import classnames from 'classnames'
import styles from './ScrollIndicator.module.scss'
import CaretUp from 'assets/svgs/caret-up.svg'
import useStore from 'store'
import gsap from 'gsap'

const MAX_DELTA = 20

function ScrollIndicator({ className, elementRef }) {
  const refs = useRef({})
  const container = useRef()
  const portfolioScrollDirection = useStore(state => state.portfolioScrollDirection)
  const portfolioScrollDistance = useStore(state => state.portfolioScrollDistance)

  useEffect(() => {
    const multiplier = portfolioScrollDirection === 'up' ? 1 : -1

    gsap.killTweensOf([container.current, refs.current.up, refs.current.down])

    const duration = 0.1

    gsap.to(container.current, {
      y: MAX_DELTA * multiplier,
      duration: duration,
      ease: 'Power3.easeOut',
      onComplete: () => {
        if (container.current) {
          gsap.to(container.current, {
            y: 0,
          })
        }
      },
    })

    gsap.to([refs.current.up, refs.current.down], {
      y: 10 * multiplier,
      duration: duration,
      ease: 'Power3.easeOut',
      onComplete: () => {
        if (refs.current.up && refs.current.down) {
          gsap.to([refs.current.up, refs.current.down], {
            y: 0,
          })
        }
      },
    })
  }, [portfolioScrollDirection, portfolioScrollDistance])

  return (
    <div
      className={classnames(styles.ScrollIndicator, className)}
      ref={ref => {
        elementRef.current = ref
      }}
    >
      <div
        className={styles.innerContainer}
        ref={container}
      >
        <div
          className={styles.up}
          ref={ref => {
            refs.current.up = ref
          }}
        >
          <CaretUp className={styles.upSvg} />
        </div>
        <div
          className={styles.down}
          ref={ref => {
            refs.current.down = ref
          }}
        >
          <CaretUp className={styles.downSvg} />
        </div>
      </div>
    </div>
  )
}

export default memo(ScrollIndicator)
