import React, { memo, useEffect, useRef } from 'react'
import classnames from 'classnames'
import styles from './Background.module.scss'
import gsap from 'gsap'
import useStore from 'store'
import { PAGE_TRANSITION_DURATION } from 'data'
import useBreakpoint from 'utils/hooks/use-breakpoint'

function Background({ className, theme, active }) {
  const wipeRef = useRef()
  const prevPath = useStore(state => state.prevPath)
  const { isMobile } = useBreakpoint()

  useEffect(() => {
    if (!theme || !wipeRef.current || !active) return

    gsap.set(wipeRef.current, {
      autoAlpha: 1,
      '--top-left-y': '100%',
      '--top-right-y': '100%',
      '--bottom-left-y': '100%',
      '--bottom-right-y': '100%',
    })

    setTimeout(() => {
      gsap.to(wipeRef.current, {
        autoAlpha: 1,
        '--top-left-y': '0%',
        ease: 'Power4.easeInOut',
        duration: PAGE_TRANSITION_DURATION,
      })

      gsap.to(wipeRef.current, {
        autoAlpha: 1,
        '--top-right-y': '0%',
        ease: 'Power4.easeInOut',
        duration: PAGE_TRANSITION_DURATION,
        delay: isMobile ? 0.05 : 0.1,
      })
    }, 40)
  }, [theme, active, prevPath, isMobile])

  if (!theme) return null

  return (
    <div className={classnames(styles.Background, className)}>
      <div
        ref={wipeRef}
        className={classnames(styles.wipe, { [styles[theme]]: theme })}
      />
    </div>
  )
}

export default memo(Background)
