/**
 * Full-screen textured quad shader
 */

/*

void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {
	outputColor = vec4(inputColor.rgb, inputColor.a);
}

*/

const shaderTest = {
  uniforms: {
    tDiffuse: { value: null },
    opacity: { value: 1.0 },
  },
  fragmentShader: `

			// Look at "useful functions" here:
			// https://thebookofshaders.com/05/

			float cubicPulse( float c, float w, float x ){
					x = abs(x - c);
					if( x>w ) return 0.0;
					x /= w;
					return 1.0 - x*x*(3.0-2.0*x);
			}

			float parabola( float x, float k ){
				return pow( 4.0*x*(1.0-x), k );
			}

			float maxBendStrengthVertical = 0.5;
			uniform float strength;
		
			void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {
				
				vec2 newUV = uv;

				float area = parabola(uv.x, 1.0);

				newUV.y += (uv.y - 0.5) * (maxBendStrengthVertical * strength) * area;

				vec4 final = vec4(0.0);

				final = texture2D(inputBuffer, vec2(uv.x,  newUV.y));

				outputColor = final;
			}
		`,
}

export default shaderTest
