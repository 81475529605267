import React, { memo, useRef, useEffect, useState } from 'react'
import NextLink from 'next/link'
import classnames from 'classnames'
import styles from './Header.module.scss'
import gsap from 'gsap'
import { getMobileLineAnimationProps } from './headerData'

function pythagorean(sideA, sideB) {
  return Math.sqrt(Math.pow(sideA, 2) + Math.pow(sideB, 2))
}

import { useRouter } from 'next/router'
import useStore from 'store'
import { PORTFOLIO_TRANSITION_DURATION } from 'data'
import useNewKeyOnWindowResize from 'utils/hooks/use-new-key-on-window-resize'
import { black, white } from 'styles/export-vars.module.scss'
import useTransitionState from 'utils/hooks/use-transition-state'

const LINKS = [
  {
    title: 'Projects',
    link: '/',
  },
  {
    title: 'About',
    link: '/about/',
  },
  {
    title: 'Contact',
    link: 'mailto:nathan@dallaire.io',
  },
]

const ROLE = 'Freelance Frontend'
const MOBILE_HEADER_OUT_DURATION = 0.4

function Header({ className }) {
  const router = useRouter()
  const siteSettings = useStore(state => state.siteSettings)
  const portfolioWebglImageLoaded = useStore(state => state.portfolioWebglImageLoaded)
  const portfolioState = useStore(state => state.portfolioState)
  const theme = useStore(state => state.theme)
  const prevTheme = useRef(null)
  const [linkIndexHovered, setLinkIndexHovered] = useState(-1)
  const [canHoverLinks, setCanHoverLinks] = useState(true)
  const setMobileHeaderOpen = useStore(state => state.setMobileHeaderOpen)
  const mobileHeaderOpen = useStore(state => state.mobileHeaderOpen)
  const prevPath = useStore(state => state.prevPath)
  const canInteract = useStore(state => state.canInteract)
  const key = useNewKeyOnWindowResize()
  const { isPortfolio } = useTransitionState()
  const [activeLinkIndex, setActiveLinkIndex] = useState(-1)

  useEffect(() => {
    let index = -1
    LINKS.forEach((item, i) => {
      if (item.link === router.asPath) {
        index = i
      }
    })
    setActiveLinkIndex(index)
  }, [router.asPath])

  const desktopRefs = useRef({
    role: null,
    roleBg: null,
    availability: null,
    availabilityBg: null,
    links: [],
    linkBackgrounds: [],
    logoContainer: null,
    container: null,
    followBall: null,
    leftContent: null,
    rightContent: null,
  })

  const mobileRefs = useRef({
    button: null,
    buttonLines: [],
    bg: null,
    content: null,
    links: [],
    logoContainer: null,
    availability: null,
    role: null,
    name: null,
  })

  useEffect(() => {
    const bars = [
      ...desktopRefs.current.linkBackgrounds,
      desktopRefs.current.roleBg,
      desktopRefs.current.availabilityBg,
    ]
    const text = [...desktopRefs.current.links, desktopRefs.current.role, desktopRefs.current.availability]

    // Set initial colors
    if (prevPath === null && !prevTheme.current) {
      const colorToTurn = theme === 'dark' ? white : black
      gsap.set(bars, {
        backgroundColor: colorToTurn,
      })
      gsap.set(text, {
        color: colorToTurn,
      })
      prevTheme.current = colorToTurn

      return
    }

    if (prevTheme.current !== theme) {
      prevTheme.current = theme
      const colorToTurn = theme === 'dark' ? white : black
      const ease = 'Power4.easeOut'
      const duration = 0.6

      gsap.to(bars, {
        transformOrigin: 'left center',
        scaleX: 1,
        ease,
        duration,
        stagger: 0.1,
        onComplete: () => {
          gsap.set(text, {
            color: colorToTurn,
          })
          gsap.to(bars, {
            transformOrigin: 'right center',
            scaleX: 0,
            ease,
            duration,
            onComplete: () => {
              gsap.set(bars, {
                backgroundColor: colorToTurn,
              })
            },
          })
        },
      })
    }
  }, [theme, prevPath])

  useEffect(() => {
    setMobileHeaderOpen(false)
  }, [key])

  useEffect(() => {
    if (!desktopRefs.current.leftContent || !desktopRefs.current.rightContent) return
    if (portfolioState === 'MAIN' && isPortfolio) return

    const isActive = portfolioState === 'TRANSITIONING_TO_MAIN' || !isPortfolio

    gsap.to([desktopRefs.current.leftContent, desktopRefs.current.rightContent], {
      y: isActive ? 0 : -100,
      autoAlpha: isActive ? 1 : 0,
      ease: 'Power3.easeOut',
      duration: PORTFOLIO_TRANSITION_DURATION,
    })
  }, [portfolioState, isPortfolio])

  useEffect(() => {
    if (!desktopRefs.current.container || !desktopRefs.current.links.length || !desktopRefs.current.followBall) return

    const itemHeight = desktopRefs.current.links[0].offsetHeight

    gsap.killTweensOf(desktopRefs.current.followBall)
    gsap.to(desktopRefs.current.followBall, {
      y: linkIndexHovered === -1 ? activeLinkIndex * itemHeight : itemHeight * linkIndexHovered,
      ease: 'Power2.easeOut',
      duration: 0.3,
    })
  }, [linkIndexHovered, activeLinkIndex])

  useEffect(() => {
    setCanHoverLinks(canInteract)
  }, [canInteract])

  const animateLogo = (active, container, delay = 0) => {
    const logoPaths = container.querySelectorAll('path')
    ;[...logoPaths].reverse().forEach((path, i) => {
      const length = path.getTotalLength()
      gsap.set(path, { strokeDasharray: length })
      gsap.fromTo(
        path,
        {
          strokeDashoffset: active ? length : 0,
        },
        {
          strokeDashoffset: active ? 0 : length,
          duration: i === 3 ? 0.4 : 0.2,
          delay,
        },
      )
    })
  }

  const animateIn = () => {
    if (mobileRefs.current.button) {
      gsap.to(mobileRefs.current.button, {
        autoAlpha: 1,
      })
    }

    if (
      !desktopRefs.current.links.length ||
      !desktopRefs.current.role ||
      !desktopRefs.current.logoContainer ||
      !desktopRefs.current.followBall
    ) {
      return
    }

    const duration = 1.2
    const ease = 'Power3.easeOut'

    animateLogo(true, desktopRefs.current.logoContainer)

    gsap.to(desktopRefs.current.followBall, {
      scale: 1,
      duration,
      ease,
    })

    gsap.to(desktopRefs.current.links, {
      y: 0,
      duration,
      ease,
      stagger: 0.2,
    })

    const rightSideItems = [desktopRefs.current.role]
    if (desktopRefs.current.availability) {
      rightSideItems.push(desktopRefs.current.availability)
    }

    gsap.to(rightSideItems, {
      y: 0,
      duration,
      ease,
      stagger: 0.2,
    })
  }

  // Animate in
  useEffect(() => {
    if (!portfolioWebglImageLoaded) return

    setTimeout(() => {
      animateIn()
    }, 50)
  }, [portfolioWebglImageLoaded])

  // Resize mobile bg to be proper size
  useEffect(() => {
    if (!mobileRefs.current.bg) return
    const distance = pythagorean(window.innerHeight, window.innerWidth)
    gsap.set(mobileRefs.current.bg, {
      width: distance,
      height: distance,
      x: -distance * 0.5,
      y: distance * 0.5,
    })
  }, [key])

  // Mobile Header/Nav animation
  useEffect(() => {
    if (
      !mobileRefs.current.button ||
      !mobileRefs.current.buttonLines.length ||
      !mobileRefs.current.bg ||
      !mobileRefs.current.links.length ||
      !mobileRefs.current.logoContainer ||
      !mobileRefs.current.role ||
      !mobileRefs.current.content
    ) {
      return
    }

    const ease = 'Power3.easeOut'
    const isActive = mobileHeaderOpen
    const duration = isActive ? 0.8 : MOBILE_HEADER_OUT_DURATION

    // Lines
    const animationProps = getMobileLineAnimationProps(mobileRefs.current.button)

    gsap.killTweensOf(mobileRefs.current.buttonLines)

    mobileRefs.current.buttonLines.forEach((lineElement, i) => {
      const properties = animationProps[i]
      const values = isActive ? properties.active : properties.inactive

      gsap.to(lineElement, {
        ...values,
        duration,
        ease,
      })
    })

    // Logo
    const logoDelay = isActive ? duration : 0
    animateLogo(isActive, mobileRefs.current.logoContainer, logoDelay * 0.5)

    // container
    gsap.set(mobileRefs.current.content, {
      pointerEvents: isActive ? 'all' : 'none',
    })

    // Links
    gsap.killTweensOf(mobileRefs.current.links)
    gsap.to(mobileRefs.current.links, {
      y: isActive ? 0 : '110%',
      duration,
      ease,
      stagger: isActive ? 0.12 : 0,
      delay: isActive ? 0.2 : 0,
    })

    // Footer
    const footerTexts = [mobileRefs.current.name, mobileRefs.current.role]
    if (mobileRefs.current.availability) {
      footerTexts.push(mobileRefs.current.availability)
    }
    gsap.killTweensOf(footerTexts)
    gsap.to(footerTexts, {
      y: isActive ? 0 : '110%',
      duration,
      ease,
      stagger: isActive ? 0.2 : 0,
      delay: isActive ? 0.25 : 0,
    })

    // Background
    gsap.killTweensOf(mobileRefs.current.bg)
    gsap.to(mobileRefs.current.bg, {
      scale: isActive ? 2.05 : 0,
      duration,
      ease,
    })
  }, [mobileHeaderOpen, key])

  const handleMobileLinkClick = link => {
    setMobileHeaderOpen(false)
    setTimeout(() => {
      router.push(link)
    }, MOBILE_HEADER_OUT_DURATION * 1000 * 0.5)
  }

  return (
    <>
      <header
        className={classnames(styles.Header, className)}
        ref={ref => {
          desktopRefs.current.container = ref
        }}
      >
        <div
          className={styles.left}
          ref={ref => {
            desktopRefs.current.leftContent = ref
          }}
        >
          <div
            className={styles.logoContainer}
            ref={ref => {
              desktopRefs.current.logoContainer = ref
            }}
          >
            <Logo className={styles.logo} />
          </div>
          <div className={styles.linkListContainer}>
            <div
              className={styles.followBall}
              ref={ref => {
                desktopRefs.current.followBall = ref
              }}
              data-color-change
            />
            <ul className={styles.linkList__items}>
              {LINKS.map((item, i) => (
                <li
                  key={i}
                  className={classnames(styles.linkList__item, {
                    [styles.isActive]: activeLinkIndex === i,
                  })}
                  onMouseOver={() => {
                    if (canHoverLinks) {
                      setLinkIndexHovered(i)
                    }
                  }}
                  onMouseOut={() => {
                    if (canHoverLinks) {
                      setLinkIndexHovered(-1)
                    }
                  }}
                  onClick={() => {
                    if (!item.link.includes('mailto')) {
                      setCanHoverLinks(false)
                      setLinkIndexHovered(i)
                    }
                  }}
                >
                  <NextLink href={item.link}>
                    <span
                      className={styles.linkList__itemText}
                      ref={ref => {
                        desktopRefs.current.links[i] = ref
                      }}
                    >
                      {item.title}
                    </span>
                    <span
                      className={styles.linkList__itemBg}
                      ref={ref => {
                        desktopRefs.current.linkBackgrounds[i] = ref
                      }}
                    />
                  </NextLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className={styles.right}
          ref={ref => {
            desktopRefs.current.rightContent = ref
          }}
        >
          <p className={styles.roleText}>
            <span
              ref={ref => {
                desktopRefs.current.role = ref
              }}
              className={styles.roleText__text}
            >
              {ROLE}
            </span>
            <span
              className={styles.roleText__bg}
              ref={ref => {
                desktopRefs.current.roleBg = ref
              }}
            />
          </p>
          <p className={styles.availability}>
            <span
              ref={ref => {
                desktopRefs.current.availability = ref
              }}
              className={styles.availability__text}
            >
              {siteSettings.availability}
            </span>
            <span
              className={styles.availabilityText__bg}
              ref={ref => {
                desktopRefs.current.availabilityBg = ref
              }}
            />
          </p>
        </div>
      </header>
      {/* 
      ----------------------------------------
      MOBILE
      ----------------------------------------
       */}
      <button
        className={classnames(styles.mobileButton, { [styles.isOpen]: mobileHeaderOpen })}
        onClick={() => {
          setMobileHeaderOpen(!mobileHeaderOpen)
        }}
        ref={ref => {
          mobileRefs.current.button = ref
        }}
        data-color-change
      >
        <div
          ref={ref => {
            mobileRefs.current.buttonLines[0] = ref
          }}
          className={classnames(styles.mobileButtonLine, styles.line1)}
          data-color-change
        />
        <div
          ref={ref => {
            mobileRefs.current.buttonLines[1] = ref
          }}
          className={classnames(styles.mobileButtonLine, styles.line2)}
          data-color-change
        />
        <div
          ref={ref => {
            mobileRefs.current.buttonLines[2] = ref
          }}
          className={classnames(styles.mobileButtonLine, styles.line3)}
          data-color-change
        />
        <div
          className={styles.mobileButtonBg}
          data-color-change
        />
      </button>
      <div
        className={styles.mobileBg}
        data-color-change
        ref={ref => {
          mobileRefs.current.bg = ref
        }}
      />
      <header
        className={classnames(styles.headerMobile, { [styles.isOpen]: mobileHeaderOpen })}
        ref={ref => {
          mobileRefs.current.content = ref
        }}
      >
        <div
          ref={ref => {
            mobileRefs.current.logoContainer = ref
          }}
          className={styles.mobileLogoContainer}
        >
          <Logo className={styles.mobileLogo} />
        </div>
        <ul className={styles.mobileLinks}>
          {LINKS.map((item, i) => (
            <li
              key={i}
              className={classnames(styles.mobileLinks__item, {
                [styles.isActiveMobile]: activeLinkIndex === i,
              })}
              onClick={() => {
                handleMobileLinkClick(item.link)
              }}
            >
              <span
                ref={ref => {
                  mobileRefs.current.links[i] = ref
                }}
                className={styles.mobileLinks__itemText}
                data-color-change
              >
                {item.title}{' '}
              </span>
            </li>
          ))}
        </ul>
        <div className={styles.mobileFooter}>
          <p className={styles.mobileFooter__nameText}>
            <span
              ref={ref => {
                mobileRefs.current.name = ref
              }}
              data-color-change
            >
              Nathan Dallaire
            </span>
          </p>
          <p className={styles.mobileFooter__roleText}>
            <span
              ref={ref => {
                mobileRefs.current.role = ref
              }}
              data-color-change
            >
              {ROLE}
            </span>
          </p>
          {siteSettings?.availability && (
            <p className={styles.mobileFooter__availability}>
              <span
                ref={ref => {
                  mobileRefs.current.availability = ref
                }}
                data-color-change
              >
                {siteSettings.availability}
              </span>
            </p>
          )}
        </div>
      </header>
    </>
  )
}

function Logo({ className }) {
  return (
    <svg
      className={className}
      id="fuck"
      viewBox="0 0 100 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.5004 28.5C41.1671 28 34.1004 28.4 32.5004 34C30.5004 41 47.0004 32.0001 51.0004 19.0001C55.0004 6.00008 53.9996 1.50008 52.5 4.00008C51.0004 6.50008 46.9996 27 49 38C53 29.1667 61.5 9.4 63.5 1C61 7.33333 56.2 22.2 57 31C63.1667 29.6667 80.1 27.6 98.5 30"
        stroke="currentColor"
        strokeWidth="2.2"
        data-color-change
      />
      <path
        d="M1.5 40C31.8333 29.8333 93 9.4 95 9"
        stroke="currentColor"
        strokeWidth="2.2"
        data-color-change
      />
      <path
        d="M21.4998 10.5C20.6664 17.5 19.0998 32.9 19.4998 38.5"
        stroke="currentColor"
        strokeWidth="2.2"
        data-color-change
      />
      <path
        d="M3 15.9999C26.1667 14.1666 76.2 11.1999 91 13.9999"
        stroke="currentColor"
        strokeWidth="2.2"
        data-color-change
      />
    </svg>
  )
}

export default memo(Header)
